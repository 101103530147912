import React from 'react';

const StyledButton = ({ type = 'button', onClick, children }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className="w-[97%] sm:w-full md:w-[42%] lg:w-[44%] xl:w-[40%] 2xl:w-[40%] py-8 bg-[#006462] text-[#ffdf76] font-semibold text-lg 2xl:text-4xl xl:text-4xl sm:text-2xl rounded-full md:text-xl lg:text-2xl"
    >
      {children}
    </button>
  );
};

export default StyledButton;
