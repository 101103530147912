import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import valide from '../img/ico-valid.png';
import non from '../img/ico-non.png';
import vorange from '../img/ico-Vorange.png';
import iconSearch from '../img/ico-loupe.png';
import icon from '../img/icone-debuttexte.png';
import StyledButton from '../components/styleButton';

const Archive = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate('/mes-informations');
  };
  const handlearchives = (e) => {
    e.preventDefault();

    navigate('/archives');
  };
  const handleCompte = (e) => {
    e.preventDefault();

    navigate('/mon-compte');
  };

  const handleLogout = () => {
    // Manually remove the token from localStorage
    localStorage.removeItem('token');

    // You can also perform any additional cleanup here

    // Optionally, navigate to the login page or home page after logout
    navigate('/installer-form-login');  // Adjust this to wherever you want to redirect the user after logout
  };


  const renderStatusIcon = (status) => {
    switch (status) {
      case 'complete':
        return <img src={valide} alt="Valide" className="w-6 h-6 inline-block" />;
      case 'incomplete':
        return <img src={non} alt="Non" className="w-6 h-6 inline-block" />;
      case 'inProgress':
        return <img src={vorange} alt="En cours" className="w-6 h-6 inline-block" />;
      default:
        return null;
    }
  };

  const documentClient = [
    { name: 'DUPON', DPStatus: 'complete', raccordementStatus: 'inProgress', consuelStatus: 'complete' },
    { name: 'MARTIN',DPStatus: 'inProgress', raccordementStatus: '', consuelStatus: '' },
    { name: 'RAY',DPStatus: 'incomplete', raccordementStatus: '', consuelStatus: '-' },
  ];

  return (
    <div>
      <Header />
      <div className="w-full h-full mx-auto px-4 md:px-4 lg:px-16 xl:px-20 2xl:px-20">
      <div className="flex  flex-col  sm:flex-col  md:justify-between  lg:justify-between  xl:justify-between      md:flex-row  lg:flex-row  xl:flex-row  2xl:flex-row  lg:items-center mb-4">
              <div>
                <div className="flex items-center">
                  <img src={icon} alt="icon" className="inline-block w-6 h-6 mx-2" />
                  <h2 className="text-black text-xl font-normal hover:underline" onClick={handleCompte}>
                    Dossiers en cours
                  </h2>
                </div>
                <ul className="mt-2 ml-4">
                  <li className="mb-1">
                    <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handlearchives}>
                    Dossiers archivés 
                    </a>
                  </li>
                  <li className="mb-1">
                    <a className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleSubmit}>
                      Mes informations
                    </a>
                  </li>
                  <li className="mb-1">
                    <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleLogout}>
                      Déconnexion
                    </a>
                  </li>
                </ul>
              </div>
              {/* <button
                type="submit"
                className="w-[97%] sm:w-full md:w-[42%] lg:w-[44%] xl:w-[50%] 2xl:w-[60%] py-10 bg-[#006462] text-[#ffdf76] font-semibold text-lg 2xl:text-4xl xl:text-4xl sm:text-2xl rounded-full md:text-xl lg:text-2xl"
                >
                CREER UN NOUVEAU DOSSIER
              </button> */}
           <StyledButton type="button" onClick={() => navigate('/creation-demande')}>
NOUVELLE DEMANDE</StyledButton>

            </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-lg uppercase bg-[#c3dddc]">
              <tr>
                <th scope="col" className="px-6 py-3 text-black font-semibold">
                  Nom Du Chantier/Client
                </th>
                <th scope="col" className="px-6 py-3 text-black font-semibold">
                  DP
                </th>
                <th scope="col" className="px-6 py-3 text-black font-semibold">
                  RACCORDEMENT (ENEDIS ou réagie)
                </th>
                <th scope="col" className="px-6 py-3 text-black font-semibold">
                  CONSUEL
                </th>
                <th scope="col" className="px-6 py-3 text-black font-semibold">
                  MES (MISE EN SERVICE)
                </th>
              </tr>
            </thead>
            <tbody>
              {documentClient.map((doc, index) => (
                <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {doc.name}
                  </th>
                  <td className="px-6 py-4">{renderStatusIcon(doc.DPStatus)}</td>
                  <td className="px-6 py-4">{renderStatusIcon(doc.raccordementStatus)}</td>
                  <td className="px-6 py-4">{renderStatusIcon(doc.consuelStatus)}</td>
                  <td className="px-6 py-4">
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div  className='mt-16'>      <Footer />
      </div>
    </div>
  );
};

export default Archive;
