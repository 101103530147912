// // pages/MyAccount.jsx
// import React from 'react';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import { useNavigate } from 'react-router-dom';
// import icon from '../img/icone-debuttexte.png';
// import StyledButton from '../components/styleButton';

// const MyAccount = () => {
//   const navigate = useNavigate();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     navigate('/mes-informations');
//   };

//   const handleClick = (e) => {
//     e.preventDefault();
//     navigate('/mon-compte');
//   };

//   const handleArchives = (e) => {
//     e.preventDefault();
//     navigate('/archives');
//   };
//   const handleLogout = () => {
//     // Manually remove the token from localStorage
//     localStorage.removeItem('token');

//     // You can also perform any additional cleanup here

//     // Optionally, navigate to the login page or home page after logout
//     navigate('/installer-form-login');  // Adjust this to wherever you want to redirect the user after logout
//   };

//   return (
//     <div>
//       <Header />
//       <div className=" ">
//         {/* Main section with current folders and create a new folder */}
//         <div className="w-full h-full mx-auto px-4 md:px-4 lg:px-12 xl:px-16 2xl:px-16">
//           <div className="">
//             <div className="flex  flex-col  sm:flex-col  md:justify-between  lg:justify-between  xl:justify-between      md:flex-row  lg:flex-row  xl:flex-row  2xl:flex-row  lg:items-center mb-4">
//               <div>
//                 <div className="flex items-center">
//                   <img src={icon} alt="icon" className="inline-block w-6 h-6 mx-2" />
//                   <h2 className="text-black text-xl font-normal hover:underline" onClick={handleClick}>
//                     Dossiers en cours
//                   </h2>
//                 </div>
//                 <ul className="mt-2 ml-4">
//                   <li className="mb-1">
//                     <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleArchives}>
//                     Dossiers archivés 
//                                         </a>
//                   </li>
//                   <li className="mb-1">
//                     <a className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleSubmit}>
//                       Mes informations
//                     </a>
//                   </li>
//                   <li className="mb-1">
//                     <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleLogout}>
//                       Déconnexion
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//               {/* <button
//                 type="submit"
//                 className="w-[97%] sm:w-full md:w-[42%] lg:w-[44%] xl:w-[50%] 2xl:w-[60%] py-10 bg-[#006462] text-[#ffdf76] font-semibold text-lg 2xl:text-4xl xl:text-4xl sm:text-2xl rounded-full md:text-xl lg:text-2xl"
//                 >
//                 CREER UN NOUVEAU DOSSIER
//               </button> */}
//              <StyledButton type="submit">
//           CREER UN NOUVEAU DOSSIER
//         </StyledButton>
//             </div>
//           </div>

//           {/* My information section */}
//           <div className="flex flex-col space-y-8">
//             <div className="flex justify-between items-center mb-4">
//               <h3 className="text-lg 2xl:text-xl xl:text-xl lg:text-lg md:text-lg font-semibold text-[#616565]">Mes informations :</h3>
//               <button className="text-red-600 font-bold text-xl underline">Modifier mes informations</button>
//             </div>
//             <div className="p-6 rounded shadow-md space-y-6">
//               <div className="bg-gray-100 p-4 space-y-4">
//                 <div className="flex flex-col md:flex-row items-center gap-3">
//                   <label className="text-sm font-medium w-full md:w-[15%] flex">Entreprise: *</label>
//                   <input type="text" className="mt-1 p-2 border rounded w-full md:w-[85%]" />
//                 </div>
//                 <div className="flex flex-col md:flex-row items-center gap-3">
//                   <label className="text-sm font-medium w-full md:w-[15%] flex">Nom du dirigeant: *</label>
//                   <input type="text" className="mt-1 p-2 border rounded w-full md:w-[85%]" />
//                 </div>
//               </div>
//               <div className="bg-white p-4 space-y-4">
//                 <div className="flex flex-col md:flex-row items-center gap-3">
//                   <label className="text-sm font-medium w-full md:w-[15%] flex">Adresse: *</label>
//                   <input type="text" className="mt-1 p-2 border rounded w-full md:w-[85%]" />
//                 </div>
//                 <div className="flex flex-col md:flex-row items-center gap-3">
//                   <label className="text-sm font-medium w-full md:w-[15%] flex">Complément d’adresse: *</label>
//                   <input type="text" className="mt-1 p-2 border rounded w-full md:w-[85%]" />
//                 </div>
//                 <div className="flex flex-col md:flex-row items-center gap-3">
//                   <label className="text-sm font-medium w-full md:w-[15%] flex">CP: *</label>
//                   <input type="text" className="mt-1 p-2 border rounded w-[100%] md:w-[35%]" />
//                   <label className="text-sm font-medium w-[100%] md:w-[15%] flex">Ville: *</label>
//                   <input type="text" className="mt-1 p-2 border rounded w-[100%] md:w-[50%]" />
//                 </div>
//               </div>
//               <div className="bg-gray-200 p-4 flex flex-col md:flex-row items-center gap-3">
//                 <label className="text-sm font-medium w-[100%] md:w-[15%] flex">Email: *</label>
//                 <input type="text" className="mt-1 p-2 border rounded w-[100%] md:w-[35%]" />
//                 <label className="text-sm font-medium w-[100%] md:w-[20%] flex">Téléphone: *</label>
//                 <input type="text" className="mt-1 p-2 border rounded w-[100%] md:w-[45%]" />
//               </div>
//               <div className="flex w-[73%] justify-end gap-3">
//               <button type="submit" className="py-2 px-6 bg-[#006462] text-[#ffdf76] font-normal text-2xl rounded-none flex">
//                 Enregistrer
//               </button>
//             </div>
//             </div>
//           </div>

//           {/* Change password section */}
//           <form className="bg-white p-6 rounded shadow-md mt-6 space-y-4">
//           <div className="flex flex-col md:flex-row items-center md:gap-3 gap-0">
//   <h3 className="text-xl font-semibold text-red-600">Changer mon Mot de Passe :</h3>
//   <input
//     type="password"
//     placeholder="MOT DE PASSE ACTUEL"
//     className="mt-0 md:mt-1 w-full md:w-[50%] p-2 border rounded"
//   />
// </div>
// <div className="flex flex-col md:flex-row items-center md:gap-3 gap-0">
//   <h3 className="text-xl font-semibold text-white">Changer mon Mot de Passe :</h3>
//   <input
//     type="password"
//     placeholder="NOUVEAU MOT DE PASSE"
//     className="mt-0 md:mt-1 w-full md:w-[50%] p-2 border rounded"
//   />
// </div>
// <div className="flex flex-col md:flex-row items-center md:gap-3 gap-0">
//   <h3 className="text-xl font-semibold text-white">Changer mon Mot de Passe :</h3>
//   <input
//     type="password"
//     placeholder="REPETEZ NOUVEAU MOT DE PASSE"
//     className="mt-0 md:mt-1 w-full md:w-[50%] p-2 border rounded"
//   />
// </div>


//             <div className="flex w-[73%] justify-end gap-3">
//               <button type="submit" className="py-2 px-6 bg-[#006462] text-[#ffdf76] font-normal text-2xl rounded-none flex">
//                 Enregistrer
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//       <div  className='mt-16'>      <Footer />
//       </div>
//     </div>
//   );
// };

// export default MyAccount;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import icon from '../img/icone-debuttexte.png';
import StyledButton from '../components/styleButton';

const MyAccount = () => {
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false); // Track if form is editable
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [modalMessage, setModalMessage] = useState(''); // State to control modal message
  const [userData, setUserData] = useState({
    entreprise: '',
    username: '',
    Adresse: '',
    Complementd_adresse: '',
    CP: '',
    Ville: '',
    email: '',
    tele: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    // Fetch user data from backend
    // axios.get('/api/users', {
        axios.get('/admin/public/api/users', {

      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then((response) => {
      setUserData(response.data); // Set user data
    })
    .catch((error) => {
      console.error('Error fetching user data:', error.response ? error.response.data : error.message);
    });
  }, []);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSave = (e) => {
    e.preventDefault();
    
    // axios.put('/api/update-user', {
        axios.put('/admin/public/api/update-user', {

      entreprise: userData.entreprise,
      username: userData.username,
      Adresse: userData.Adresse,
      Complementd_adresse: userData.Complementd_adresse,
      CP: userData.CP,
      Ville: userData.Ville,
      email: userData.email,
      tele: userData.tele,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then((response) => {
      setIsEditable(false);
      showModalWithMessage('Information mise à jour avec succès');
    })
    .catch((error) => {
      console.error('Error updating user data:', error.response ? error.response.data : error.message);
      alert('Erreur lors de la mise à jour des informations');
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userData.newPassword !== userData.confirmPassword) {
        alert("Le nouveau mot de passe et la confirmation ne correspondent pas.");
        return;
    }

    axios.post('/api/update-password', {
        currentPassword: userData.currentPassword,
        newPassword: userData.newPassword,
        newPassword_confirmation: userData.confirmPassword
    }, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    .then((response) => {
        showModalWithMessage('Mot de passe mis à jour avec succès');
    })
    .catch((error) => {
        console.error('Error updating password:', error.response ? error.response.data : error.message);
        alert('Erreur lors de la mise à jour du mot de passe');
    });
  };

  const showModalWithMessage = (message) => {
    setModalMessage(message);
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 3000); // Hide modal after 3 seconds
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/installer-form-login');
  };

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  return (
    <div>
      <Header />
      <div className=" ">
        <div className="w-full h-full mx-auto px-4 md:px-4 lg:px-12 xl:px-16 2xl:px-16">
          <div className="">
            <div className="flex flex-col sm:flex-col md:justify-between lg:justify-between xl:justify-between md:flex-row lg:flex-row xl:flex-row 2xl:flex-row lg:items-center mb-4">
              <div>
                <div className="flex items-center">
                  <img src={icon} alt="icon" className="inline-block w-6 h-6 mx-2" />
                  <h2 className="text-black text-xl font-normal hover:underline" onClick={() => navigate('/mon-compte')}>
                    Dossiers en cours
                  </h2>
                </div>
                <ul className="mt-2 ml-4">
                  <li className="mb-1">
                    <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={() => navigate('/archives')}>
                      Dossiers archivés
                    </a>
                  </li>
                  <li className="mb-1">
                    <a className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={() => navigate('/mes-informations')}>
                      Mes informations
                    </a>
                  </li>
                  <li className="mb-1">
                    <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleLogout}>
                      Déconnexion
                    </a>
                  </li>
                </ul>
              </div>
              <StyledButton type="button" onClick={() => navigate('/creation-demande')}>
  NOUVELLE DEMANDE
</StyledButton>
            </div>
          </div>

          {/* Information Section */}
          <div className="flex flex-col space-y-8">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg 2xl:text-xl xl:text-xl lg:text-lg md:text-lg font-semibold text-[#616565]">Mes informations :</h3>
              <button onClick={toggleEdit} className="text-red-600 font-bold text-xl underline">
                {isEditable ? 'Annuler' : 'Modifier mes informations'}
              </button>
            </div>
            <div className="p-6 rounded shadow-md space-y-6">
              <div className="bg-gray-100 p-4 space-y-4">
                <div className="flex flex-col md:flex-row items-center gap-3">
                  <label className="text-sm font-medium w-full md:w-[15%] flex">Entreprise: *</label>
                  <input
                    type="text"
                    className="mt-1 p-2 border rounded w-full md:w-[85%]"
                    name="entreprise"
                    value={userData.entreprise}
                    onChange={handleChange}
                    readOnly={!isEditable}
                  />
                </div>
                <div className="flex flex-col md:flex-row items-center gap-3">
                  <label className="text-sm font-medium w-full md:w-[15%] flex">Nom du dirigeant: *</label>
                  <input
                    type="text"
                    className="mt-1 p-2 border rounded w-full md:w-[85%]"
                    name="username"
                    value={userData.username}
                    onChange={handleChange}
                    readOnly={!isEditable}
                  />
                </div>
              </div>
              <div className="bg-white p-4 space-y-4">
                <div className="flex flex-col md:flex-row items-center gap-3">
                  <label className="text-sm font-medium w-full md:w-[15%] flex">Adresse: *</label>
                  <input
                    type="text"
                    className="mt-1 p-2 border rounded w-full md:w-[85%]"
                    name="Adresse"
                    value={userData.Adresse}
                    onChange={handleChange}
                    readOnly={!isEditable}
                  />
                </div>
                <div className="flex flex-col md:flex-row items-center gap-3">
                  <label className="text-sm font-medium w-full md:w-[15%] flex">Complément d’adresse: *</label>
                  <input
                    type="text"
                    className="mt-1 p-2 border rounded w-full md:w-[85%]"
                    name="Complementd_adresse"
                    value={userData.Complementd_adresse}
                    onChange={handleChange}
                    readOnly={!isEditable}
                  />
                </div>
                <div className="flex flex-col md:flex-row items-center gap-3">
                  <label className="text-sm font-medium w-full md:w-[15%] flex">CP: *</label>
                  <input
                    type="number"
                    className="mt-1 p-2 border rounded w-[100%] md:w-[35%]"
                    name="CP"
                    value={userData.CP}
                    onChange={handleChange}
                    readOnly={!isEditable}
                  />
                  <label className="text-sm font-medium w-[100%] md:w-[4%] flex">Ville: *</label>
                  <input
                    type="text"
                    className="mt-1 p-2 border rounded w-[100%] md:w-[45%]"
                    name="Ville"
                    value={userData.Ville}
                    onChange={handleChange}
                    readOnly={!isEditable}
                  />
                </div>
              </div>
              <div className="bg-gray-200 p-4 flex flex-col md:flex-row items-center gap-3">
                <label className="text-sm font-medium w-[100%] md:w-[15%] flex">Email: *</label>
                <input
                  type="email"
                  className="mt-1 p-2 border rounded w-[100%] md:w-[35%]"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  readOnly={!isEditable}
                />
                <label className="text-sm font-medium w-[100%] md:w-[7%] flex">Téléphone: *</label>
                <input
                  type="text"
                  className="mt-1 p-2 border rounded w-[100%] md:w-[45%]"
                  name="tele"
                  value={userData.tele}
                  onChange={handleChange}
                  readOnly={!isEditable}
                />
              </div>
              {isEditable && (
                <div className="flex w-[73%] justify-end gap-3">
                  <button onClick={handleSave} className="py-2 px-6 bg-[#006462] text-[#ffdf76] font-normal text-2xl rounded-none flex">
                    Enregistrer
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Change Password Section */}
          <form className="bg-white p-6 rounded shadow-md mt-6 space-y-4" onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row items-center md:gap-3 gap-0">
              <h3 className="text-xl font-semibold text-red-600 md:w-[30%]">Mot de Passe Actuel :</h3>
              <input
                type="password"
                placeholder="Mot de Passe Actuel"
                className="mt-0 md:mt-1 w-full md:w-[70%] p-2 border rounded"
                name="currentPassword"
                value={userData.currentPassword}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex flex-col md:flex-row items-center md:gap-3 gap-0">
              <h3 className="text-lg font-semibold text-white md:w-[30%]">Nouveau Mot de Passe :</h3>
              <input
                type="password"
                placeholder="Nouveau Mot de Passe"
                className="mt-0 md:mt-1 w-full md:w-[70%] p-2 border rounded"
                name="newPassword"
                value={userData.newPassword}
                onChange={handleChange}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                title="Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre."
                required
              />
            </div>
            <div className="flex flex-col md:flex-row items-center md:gap-3 gap-0">
              <h3 className="text-xl font-semibold text-white md:w-[30%]">Confirmer le Nouveau Mot de Passe :</h3>
              <input
                type="password"
                placeholder="Confirmer Nouveau Mot de Passe"
                className="mt-0 md:mt-1 w-full md:w-[70%] p-2 border rounded"
                name="confirmPassword"
                value={userData.confirmPassword}
                onChange={handleChange}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                title="Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre."
                required
              />
            </div>
            <div className="flex w-[100%] justify-end gap-3 mt-4">
              <button type="submit" className="py-2 px-6 bg-[#006462] text-[#ffdf76] font-normal text-2xl rounded-none flex">
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg text-center">
            <h2 className="text-xl font-semibold">{modalMessage}</h2>
          </div>
        </div>
      )}

      <div className="mt-16">
        <Footer />
      </div>
    </div>
  );
};

export default MyAccount;
