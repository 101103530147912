import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/tailwind.css';
import icon from '../img/icone-debuttexte.png';
import Header from '../components/Header';
import Footer from '../components/Footer';
import image from '../img/deco-bas de page.png'; 

const ExamplePage = () => {
    const navigate = useNavigate();
    
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="relative container mx-auto px-4 py-8">
                <div className="">
                    <div className="flex items-center mb-24 ml-12">
                        <img src={icon} alt="icon" className="w-8 h-8 mr-3" />
                        <h1 className="text-3xl font-medium text-[#616565] ">Informations&Tarifs :</h1>
                    </div>
                    <div className="mt-4 text-black text-[20px] font-semibold space-y-16 ml-24">
                        <p>
                            Dum haec in oriente aguntur, Arelate hiemem agens Constantius post theatralis ludos atque circenses
                            ambiitioso editos apparatu diem sextum idus Octobres, qui imperii eius annum tricensimum terminabat,
                            insolentiae pondera gravius librans, siquid dubium deferabatur aut falsum, pro liquido accipiens et
                            conperto, inter alia excarnificatum Gerontium Magnentianae comitem partis exulari maerore multavit.
                        </p>
                        <p>
                            Huic Arabia est conserta, ex alio latere Nabataeis contigua; opima varietate commerciorum castrisque
                            oppleta validis et castellis, quae ad repellendos gentium vicinarum excursus sollicitudo pervigili
                            veterum per oportunos saltus erexit et cautos. haec quoque civitates habet inter oppida quaedam ingentes
                            Bostram et Gerasam atque Philadelphiam murorum firmitate cautissimas. hanc provinciae inposito nomine
                            rectoreque attributo obtemperare legibus nostris Traianus conpulit imperator incolarum tumore saepe
                            contunso cum glorioso marte Mediam urgeret et Parthos.
                        </p>
                        <p className="mb-24">
                            Huic Arabia est conserta, ex alio latere Nabataeis contigua; opima varietate commerciorum castrisque
                            oppleta validis et castellis, quae ad repellendos gentium vicinarum excursus sollicitudo pervigili
                            veterum per oportunos saltus erexit et cautos. haec quoque civitates habet inter oppida quaedam ingentes
                            Bostram et Gerasam atque Philadelphiam murorum firmitate cautissimas. hanc provinciae inposito nomine
                            rectoreque attributo obtemperare legibus nostris Traianus conpulit imperator incolarum tumore saepe
                            contunso cum glorioso marte Mediam urgeret et Parthos.
                        </p>
                    </div>
                </div>
                <div className="relative pt-48  bottom-1 "> 
                    <img src={image} alt="Image" className="w-auto h-auto mx-auto" />
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default ExamplePage;
