// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import '../style/tailwind.css';
// import icon from '../img/icone-debuttexte.png';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import image from '../img/deco-bas de page.png'; 

// const ExamplePage = () => {
//     const navigate = useNavigate();
    
//     return (
//         <div className="flex flex-col min-h-screen">
//             <Header />
//             <main className="relative container mx-auto px-4 py-8">
//                 <div className="">
//                     <div className="flex items-center mb-24 ml-12">
//                         <img src={icon} alt="icon" className="w-8 h-8 mr-3" />
//                         <h1 className="text-3xl font-medium text-[#616565] ">Page texte exemple / RGPD - CGV / CGU :</h1>
//                     </div>
//                     <div className="mt-4 text-black text-[20px] font-semibold space-y-16 ml-24">
//                         <p>
//                             Dum haec in oriente aguntur, Arelate hiemem agens Constantius post theatralis ludos atque circenses
//                             ambiitioso editos apparatu diem sextum idus Octobres, qui imperii eius annum tricensimum terminabat,
//                             insolentiae pondera gravius librans, siquid dubium deferabatur aut falsum, pro liquido accipiens et
//                             conperto, inter alia excarnificatum Gerontium Magnentianae comitem partis exulari maerore multavit.
//                         </p>
//                         <p>
//                             Huic Arabia est conserta, ex alio latere Nabataeis contigua; opima varietate commerciorum castrisque
//                             oppleta validis et castellis, quae ad repellendos gentium vicinarum excursus sollicitudo pervigili
//                             veterum per oportunos saltus erexit et cautos. haec quoque civitates habet inter oppida quaedam ingentes
//                             Bostram et Gerasam atque Philadelphiam murorum firmitate cautissimas. hanc provinciae inposito nomine
//                             rectoreque attributo obtemperare legibus nostris Traianus conpulit imperator incolarum tumore saepe
//                             contunso cum glorioso marte Mediam urgeret et Parthos.
//                         </p>
//                         <p className="mb-24">
//                             Huic Arabia est conserta, ex alio latere Nabataeis contigua; opima varietate commerciorum castrisque
//                             oppleta validis et castellis, quae ad repellendos gentium vicinarum excursus sollicitudo pervigili
//                             veterum per oportunos saltus erexit et cautos. haec quoque civitates habet inter oppida quaedam ingentes
//                             Bostram et Gerasam atque Philadelphiam murorum firmitate cautissimas. hanc provinciae inposito nomine
//                             rectoreque attributo obtemperare legibus nostris Traianus conpulit imperator incolarum tumore saepe
//                             contunso cum glorioso marte Mediam urgeret et Parthos.
//                         </p>
//                     </div>
//                 </div>
//                 <div className="relative pt-48  bottom-1 "> {/* Ajouter du padding-top pour créer de l'espace */}
//                     <img src={image} alt="Image" className="w-auto h-auto mx-auto" />
//                 </div>
//             </main>
//             <Footer />
//         </div>
//     );
// }

// export default ExamplePage;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/tailwind.css';
import icon from '../img/icone-debuttexte.png';
import Header from '../components/Header';
import Footer from '../components/Footer';
import image from '../img/deco-bas de page.png';

const ExamplePage = () => {
    const [rgpdContent, setRgpdContent] = useState('');  // État pour stocker le contenu RGPD
    const navigate = useNavigate();

    useEffect(() => {
        // Récupérer le contenu RGPD depuis le backend
        // axios.get('http://localhost:8000/api/rgpd')
        axios.get('https://www.lampenergie.fr/admin/public/api/rgpd')

            .then(response => {
                // Vérifie que le contenu existe dans la réponse
                if (response.data && response.data.content) {
                    setRgpdContent(response.data.content);  // Stocker le contenu dans l'état
                } else {
                    console.error("Content not found in the response.");
                }
            })
            .catch(error => {
                console.error("There was an error fetching the RGPD content!", error);
            });
    }, []);

    // Fonction pour diviser le contenu en paragraphes de 5 lignes
    const formatContentToParagraphs = (content) => {
        const lines = content.split('\n'); // Suppose que chaque saut de ligne représente une nouvelle ligne
        const paragraphs = [];
        let currentParagraph = [];

        lines.forEach((line, index) => {
            currentParagraph.push(line); // Ajouter la ligne au paragraphe actuel
            // Vérifier si le paragraphe actuel a atteint 5 lignes
            if (currentParagraph.length === 5 || index === lines.length - 1) {
                paragraphs.push(currentParagraph.join('\n')); // Joindre les lignes en un paragraphe
                currentParagraph = []; // Réinitialiser le paragraphe actuel
            }
        });

        return paragraphs;
    };

    // Obtenir le contenu formaté
    const formattedContent = formatContentToParagraphs(rgpdContent);

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="relative container mx-auto px-4 py-8">
                <div>
                    <div className="flex items-center mb-24 ml-12">
                        <img src={icon} alt="icon" className="w-8 h-8 mr-3" />
                        <h1 className="text-3xl font-medium text-[#616565]">Page texte exemple / RGPD - CGV / CGU :</h1>
                    </div>
                    <div className="mt-4 text-black text-[20px] font-semibold space-y-6 ml-24">
                        {/* Afficher le contenu RGPD dynamiquement avec des paragraphes */}
                        {formattedContent.map((paragraph, index) => (
                            <p key={index} className="mb-4">{paragraph}</p>
                        ))}
                    </div>
                </div>
                <div className="relative pt-48 bottom-1"> {/* Ajouter du padding-top pour créer de l'espace */}
                    <img src={image} alt="Image" className="w-auto h-auto mx-auto " />
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default ExamplePage;
