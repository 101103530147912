import { configureStore } from '@reduxjs/toolkit';
import demandeReducer from '../demandeSlice';

const store = configureStore({
  reducer: {
    demandes: demandeReducer,
  },
});

export default store;
