// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { updateFormData } from '../demandeSlice';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import Modal from '../components/modal';
// import { useLocation } from 'react-router-dom';

// const Demande2 = () => {
//   const dispatch = useDispatch();
//   const formData = useSelector((state) => state.demandes.formData);
//   const location = useLocation();

//   // Access files passed from Demande1
//   const { kbisEntrepriseFile, mandatSpecialFile, documentProprieteFile } = location.state || {};

//   const [files, setFiles] = useState({
//     copieFacture: null,
//     propriete: documentProprieteFile || null,
//     dessinsSchemaPhoto: [],
//     photosAutresDocs: [],
//     mandat_special: mandatSpecialFile || null,
//     kbis_entreprise: kbisEntrepriseFile || null,
//     document_propriete: documentProprieteFile || null,
//     cin: null, 
//   });

//   const [showModal, setShowModal] = useState(false);
//   const [modalContent, setModalContent] = useState('');
//   const [signupData, setSignupData] = useState({
//     entreprise: '',
//     username: '',
//     Adresse: '',
//     Complementd_adresse: '',
//     CP: '',
//     Ville: '',
//     email: '',
//     tele: '',
//     password: '',
//   });

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     if (modalContent === 'signup') {
//       setSignupData({ ...signupData, [name]: value });
//     } else {
//       dispatch(updateFormData({
//         [name]: type === 'checkbox' ? checked : value,
//       }));
//     }
//   };

//   const handleFileChange = (event, field, multiple = false) => {
//     const selectedFiles = multiple ? Array.from(event.target.files) : event.target.files[0];
//     setFiles((prevFiles) => ({
//       ...prevFiles,
//       [field]: multiple ? [...prevFiles[field], ...selectedFiles] : selectedFiles,
//     }));
//   };

//   const handleModalOk = () => {
//     setShowModal(false);
//     // Ne pas réinitialiser les fichiers et les données du formulaire ici
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate required fields if autoConsommation is checked
//     if (formData.autoConsommation) {
//         if (!files.cin) {
//             alert('Le champ CIN est requis.');
//             return;
//         }
//         if (!formData.nom_beneficiaire) {
//             alert('Le champ Nom du bénéficiaire est requis.');
//             return;
//         }
//     }

//     const token = localStorage.getItem('token');
//     if (!token) {
//       setModalContent('signup');
//       setShowModal(true);
//       return;
//     }

//     await submitDemande(token);
//   };

//   const submitDemande = async (token) => {
//     const formDataToSend = new FormData();
  
//     // Ajout du fichier CIN
//     if (files.cin) formDataToSend.append('cin', files.cin);
  
//     // Append individual files from Demande1
//     if (files.kbis_entreprise) formDataToSend.append('kbis_entreprise', files.kbis_entreprise);
//     if (files.mandat_special) formDataToSend.append('mandat_special', files.mandat_special);
//     if (files.document_propriete) formDataToSend.append('document_propriete', files.document_propriete);
//     if (files.copieFacture) formDataToSend.append('copie_facture', files.copieFacture);
//     if (files.propriete) formDataToSend.append('propriete', files.propriete);
  
//     // Append multiple files from the modal
//     if (files.dessinsSchemaPhoto.length > 0) {
//       files.dessinsSchemaPhoto.forEach((file) => formDataToSend.append('dessins_schema_photo[]', file));
//     }
//     if (files.photosAutresDocs.length > 0) {
//       files.photosAutresDocs.forEach((file) => formDataToSend.append('photos_autres_docs[]', file));
//     }
  
//     // Append other form data
//     const branchementCompteur = [];
//     if (formData.monophasé) branchementCompteur.push('Monophasé');
//     if (formData.triphasé) branchementCompteur.push('Triphasé');
//     formDataToSend.append('branchement_compteur', branchementCompteur.join(','));
  
//     Object.keys(formData).forEach((key) => {
//       if (formData[key] !== undefined && formData[key] !== null && formData[key] !== '') {
//         formDataToSend.append(key, formData[key].toString());
//       }
//     });
  
//     try {
//       const response = await fetch('http://localhost:8000/api/demande/add', {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Authorization': token ? `Bearer ${token}` : '',
//         },
//         body: formDataToSend,
//       });
  
//       if (!response.ok) {
//         const errorData = await response.json();
//         console.error('Validation errors:', errorData.errors || errorData.message || 'Unknown error occurred');
//         setModalContent('error');
//       } else {
//         const data = await response.json();
//         console.log('Success:', data);
//         setModalContent('success');
//       }
//     } catch (error) {
//       console.error('Error:', error.message || error);
//       setModalContent('error');
//     }
//     setShowModal(true);
//   };
  
//   const handleSignupSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch('http://localhost:8000/api/register', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           entreprise: signupData.entreprise,
//           username: signupData.username,
//           Adresse: signupData.Adresse,
//           Complementd_adresse: signupData.Complementd_adresse,
//           CP: signupData.CP,
//           Ville: signupData.Ville,
//           email: signupData.email,
//           tele: signupData.tele,
//           password: signupData.password,
//           role: 'installateur', // Default role
//         }),
//       });

//       const data = await response.json();
//       console.log('Fichiers enregistrés:', data.files); // Supposons que le backend retourne les fichiers ici

//       if (!response.ok) {
//         const contentType = response.headers.get('content-type');
//         let errorData;

//         if (contentType && contentType.includes('application/json')) {
//           errorData = await response.json();
//         } else {
//           errorData = { message: 'Unexpected response format from server' };
//         }

//         console.error('Signup errors:', errorData.errors || errorData.message || 'Unknown error occurred');
//         setModalContent('error');
//       } else {
//         const data = await response.json();
//         localStorage.setItem('token', data.token); // Store the token
//         await submitDemande(data.token); // Submit the demande after successful signup
//       }
//     } catch (error) {
//       console.error('Error:', error.message || error);
//       setModalContent('error');
//     }
//   };

//   const handleModalClose = () => {
//     setShowModal(false);

//     // Réinitialiser les champs du formulaire
//     dispatch(updateFormData({
//         ref_cadastrale_section: '',
//         ref_cadastrale_parcelle: '',
//         surface_totale_parcelle: '',
//         coordonnees_gps: '',
//         monophasé: false,
//         triphasé: false,
//         installation: 'non',
//         date_mes: '',
//         numero_crae: '',
//         marque_ancien_onduleur: '',
//         modele_ancien_onduleur: '',
//         puissance_ancienne_installation: '',
//         nom: '',
//         prenom: '',
//         date_de_naissance: '',
//         lieu_de_naissance: '',
//         pays: '',
//         adresse: '',
//         complement_adresse: '',
//         cp: '',
//         ville: '',
//         telephone_fixe: '',
//         telephone_mobile: '',
//         email: '',
//         autoConsommation: false,
//         nom_beneficiaire: '',
//     }));

//     // Réinitialiser les fichiers
//     setFiles({
//         copieFacture: null,
//         propriete: null,
//         dessinsSchemaPhoto: [],
//         photosAutresDocs: [],
//         mandat_special: null,
//         kbis_entreprise: null,
//         document_propriete: null,
//         cin: null,
//     });
//   };

//   const renderModalContent = () => {
//     switch (modalContent) {
//         case 'success':
//             return (
//                 <div className="bg-white p-6 rounded shadow-lg">
//                     <h2 className="text-xl font-semibold mb-4">Enregistrement réussi</h2>
//                     <p>Votre demande a été enregistrée avec succès.</p>
//                     <button
//                         className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
//                         onClick={handleModalClose} // Appel de la fonction de réinitialisation
//                     >
//                         Fermer
//                     </button>
//                 </div>
//             );
//         case 'error':
//             return (
//                 <div className="bg-white p-6 rounded shadow-lg">
//                     <h2 className="text-xl font-semibold mb-4">Erreur</h2>
//                     <p>Une erreur est survenue lors de l'enregistrement. Veuillez vérifier les champs et réessayer.</p>
//                     <button
//                         className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
//                         onClick={() => setShowModal(false)}
//                     >
//                         Fermer
//                     </button>
//                 </div>
//             );
//         case 'signup':
//             return (
//                 <div className="bg-white p-6 rounded shadow-lg">
//                     <h2 className="text-xl font-semibold mb-4">Connexion requise</h2>
//                     <p>Vous devez être connecté pour soumettre ce formulaire. Veuillez vous inscrire ou vous connecter.</p>
//                     <form onSubmit={handleSignupSubmit}>
//                         <input
//                             type="text"
//                             name="entreprise"
//                             placeholder="Entreprise"
//                             value={signupData.entreprise}
//                             onChange={handleChange}
//                             className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
//                         />
//                         <input
//                             type="text"
//                             name="username"
//                             placeholder="Nom du dirigeant"
//                             value={signupData.username}
//                             onChange={handleChange}
//                             className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
//                         />
//                         <input
//                             type="text"
//                             name="Adresse"
//                             placeholder="Adresse"
//                             value={signupData.Adresse}
//                             onChange={handleChange}
//                             className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
//                         />
//                         <input
//                             type="text"
//                             name="Complementd_adresse"
//                             placeholder="Complément d’adresse"
//                             value={signupData.Complementd_adresse}
//                             onChange={handleChange}
//                             className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
//                         />
//                         <input
//                             type="number"
//                             name="CP"
//                             placeholder="CP"
//                             value={signupData.CP}
//                             onChange={handleChange}
//                             className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
//                         />
//                         <input
//                             type="text"
//                             name="Ville"
//                             placeholder="Ville"
//                             value={signupData.Ville}
//                             onChange={handleChange}
//                             className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
//                         />
//                         <input
//                             type="email"
//                             name="email"
//                             placeholder="Email"
//                             value={signupData.email}
//                             onChange={handleChange}
//                             className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
//                         />
//                         <input
//                             type="text"
//                             name="tele"
//                             placeholder="Téléphone"
//                             value={signupData.tele}
//                             onChange={handleChange}
//                             className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
//                         />
//                         <input
//                             type="password"
//                             name="password"
//                             placeholder="Mot de passe"
//                             value={signupData.password}
//                             onChange={handleChange}
//                             className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
//                         />
//                         <button
//                             type="submit"
//                             className="mt-4 py-2 px-4 bg-[#006462] text-white rounded w-full"
//                         >
//                             S'inscrire / Se connecter
//                         </button>
//                     </form>
//                 </div>
//             );
//         default:
//             return null;
//     }
//   };

//   return (
//     <div>
//       <Header />
//       <div className="bg-white p-4 lg:mx-12">
//         <form onSubmit={handleSubmit} className="space-y-6">
//           <h2 className="2xl:text-3xl text-[25px] font-medium text-[#616565]">Références cadastrales du terrain :</h2>
//           <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-0 px-2">
//             <div className="flex flex-col lg:flex-row items-center gap-2">
//               <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[30%] 2xl:text-end md:w-[100%]">
//                 N° de section :
//               </label>
//               <input
//                 type="number"
//                 name="ref_cadastrale_section"
//                 value={formData.ref_cadastrale_section || ''}
//                 onChange={handleChange}
//                 className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[50%] md:w-[100%]"
//               />
//             </div>
//             <div className="flex flex-col lg:flex-row items-center gap-2 ">
//               <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[30%] 2xl:text-end md:w-[100%]">
//                 N° de parcelle :
//               </label>
//               <input
//                 type="number"
//                 name="ref_cadastrale_parcelle"
//                 value={formData.ref_cadastrale_parcelle || ''}
//                 onChange={handleChange}
//                 className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[50%] md:w-[100%]"
//               />
//             </div>
//             <div className="flex flex-col lg:flex-row items-center gap-2 xl:gap-1">
//               <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[80%] 2xl:text-end">
//                 Surface totale :
//               </label>
//               <input
//                 type="number"
//                 name="surface_totale_parcelle"
//                 value={formData.surface_totale_parcelle || ''}
//                 onChange={handleChange}
//                 className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[60%]"
//               />
//               <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[40%]">
//                 M<sup>2</sup>
//               </label>
//             </div>
//           </div>

//           <div className="flex flex-wrap sm:flex-row w-full">
//             <label className="w-full text-[#1c7573] font-medium text-[16px] 2xl:w-[20%] xl:w-[20%] lg:w-[30%] md:w-[30%]">
//               Coordonnées GPS ou Google Earth :
//             </label>
//             <input
//               type="text"
//               name="coordonnees_gps"
//               value={formData.coordonnees_gps || ''}
//               onChange={handleChange}
//               className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[70%] md:w-[70%] 2xl:w-[80%] xl:w-[80%]"
//             />
//           </div>

//           <h2 className="text-[25px] 2xl:text-2xl font-medium text-[#616565]">
//             Éléments de facture EDF du client producteur (ou copie de la facture complète)
//           </h2>
//           <div className="flex flex-col sm:flex-row items-center space-x-4 sm:ml-">
//             <div className="ml-5">
//               <label className="text-[#1c7573] text-lg font-semibold md:text-[1.12rem]">
//                 Copie facture, photos, disjoncteur et compteur :
//               </label>
//             </div>

//                {/* Button to show modal */}
//             <div>
//               <div
//                 className="py-3 px-8 bg-[#006462] text-[#ffdf76] font-normal text-2xl rounded-none flex items-center cursor-pointer lg:w-full md:w-[100%] w-[70%]"
//                 onClick={() => setShowModal(true)}
//               >
//                 Télécharger des fichiers
//               </div>
//             </div>
//           </div>

//           <div className="flex flex-col sm:flex-row items-baseline space-x-4 ml-5">
//             <label className="text-[#1c7573] font-medium">Branchement (compteur) :</label>
//             <label className="flex items-center font-semibold text-[#1c7573] space-x-2">
//               <span>Monophasé</span>
//               <input
//                 type="checkbox"
//                 name="monophasé"
//                 checked={formData.monophasé || false}
//                 onChange={handleChange}
//                 className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
//               />
//             </label>
//             <label className="flex items-center font-semibold text-[#1c7573] space-x-2">
//               <span>Triphasé</span>
//               <input
//                 type="checkbox"
//                 name="triphasé"
//                 checked={formData.triphasé || false}
//                 onChange={handleChange}
//                 className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
//               />
//             </label>
//           </div>

//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ml-5">
//             <div className="flex flex-col sm:flex-row items-left space-x-2">
//               <label className="text-[#ff1c1e] italic font-medium">
//                 Disposez-vous déjà d'une installation photovoltaïque ?
//               </label>
//               <label className="flex items-center text-[#ff1c1e] italic space-x-2">
//                 <input
//                   type="radio"
//                   name="installation"
//                   value="non"
//                   checked={formData.installation === 'non'}
//                   onChange={handleChange}
//                   className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
//                 />
//                 <span>NON</span>
//               </label>
//               <label className="flex items-center text-[#ff1c1e] italic space-x-2">
//                 <input
//                   type="radio"
//                   name="installation"
//                   value="oui"
//                   checked={formData.installation === 'oui'}
//                   onChange={handleChange}
//                   className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
//                 />
//                 <span>OUI</span>
//               </label>
//             </div>
//           </div>

//           {formData.installation === 'oui' && (
//             <>
//               <div className="flex flex-col sm:flex-row items-left space-x-1 italic font-medium ml-5">
//                 <div className="flex flex-col sm:flex-row items-center space-x-1">
//                   <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Date de MES :</label>
//                   <input
//                     type="date"
//                     name="date_mes"
//                     placeholder='A/M/J'
//                     value={formData.date_mes || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[40%]"
//                   />
//                 </div>
//                 <div className="flex flex-col sm:flex-row items-center space-x-1 w-full">
//                   <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">N° de CRAE : 0000</label>
//                   <input
//                     type="text"
//                     name="numero_crae"
//                     value={formData.numero_crae || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[15%]"
//                   />
//                 </div>
//               </div>

//               <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
//                 <div className="flex flex-col sm:flex-row items-center space-x-2">
//                   <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Marque de l'ancien onduleur :</label>
//                   <input
//                     type="text"
//                     name="marque_ancien_onduleur"
//                     value={formData.marque_ancien_onduleur || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[43%]"
//                   />
//                 </div>
//                 <div className="flex flex-col sm:flex-row items-center space-x-2">
//                   <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Modèle :</label>
//                   <input
//                     type="text"
//                     name="modele_ancien_onduleur"
//                     value={formData.modele_ancien_onduleur || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[62%]"
//                   />
//                 </div>
//               </div>

//               <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
//                 <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">
//                   Puissance de l'ancienne installation de production (kWc) :
//                 </label>
//                 <input
//                   type="number"
//                   placeholder='exp:20.9'
//                   name="puissance_ancienne_installation"
//                   value={formData.puissance_ancienne_installation || ''}
//                   onChange={handleChange}
//                   className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[16%]"
//                 />
//               </div>
//             </>
//           )}

//           {formData.installation === 'non' && (
//             <>
//               <div className="mb-4">
//                 <div className="flex flex-col md:flex-row items-left md:items-center">
//                   <h2 className="text-[24px] 2xl:text-3xl font-medium text-[#616565]">
//                     Informations et coordonnées complètes du client/producteur :
//                   </h2>
//                   <div className="mt-4 md:mt-0 md:ml-4 flex items-center">
//                     <input
//                       type="checkbox"
//                       name="autoConsommation"
//                       checked={formData.autoConsommation || false}
//                       onChange={handleChange}
//                       className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
//                     />
//                     <label className="text-[#1c7573] font-medium text-[16px]">AUTOCONSOMMATION</label>
//                   </div>
//                 </div>
//               </div>

//               <div className="grid grid-cols-1 gap-4 ml-5">
//                 <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px]">Nom :*</label>
//                   <input
//                     type="text"
//                     name="nom"
//                     value={formData.nom || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
//                   />
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Prénom :*</label>
//                   <input
//                     type="text"
//                     name="prenom"
//                     value={formData.prenom || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
//                   />
//                 </div>
//                 <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px]">Date de naissance :*</label>
//                   <input
//                     type="date"
//                     placeholder='A/M/J'
//                     name="date_de_naissance"
//                     value={formData.date_de_naissance || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[18%]"
//                   />
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Lieu de naissance :*</label>
//                   <input
//                     type="text"
//                     name="lieu_de_naissance"
//                     value={formData.lieu_de_naissance || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
//                   />
//                 </div>
//                 <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px]">Pays :*</label>
//                   <input
//                     type="text"
//                     name="pays"
//                     value={formData.pays || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
//                   />
//                 </div>
//                 <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px]">Adresse :*</label>
//                   <input
//                     type="text"
//                     name="adresse"
//                     value={formData.adresse || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[57%]"
//                   />
//                 </div>
//                 <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px]">Complément d'adresse :</label>
//                   <input
//                     type="text"
//                     name="complement_adresse"
//                     value={formData.complement_adresse || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[49.5%]"
//                   />
//                 </div>
//                 <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px]">CP :*</label>
//                   <input
//                     type="number"
//                     name="cp"
//                     value={formData.cp || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[18%]"
//                   />
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Ville :*</label>
//                   <input
//                     type="text"
//                     name="ville"
//                     value={formData.ville || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[45.5%]"
//                   />
//                 </div>
//                 <div className="flex flex-col sm:flex-row items-left space-x-1">
//                   <div className="flex flex-col sm:flex-row items-center space-x-1 gap-4">
//                     <label className="w-full sm:w-[70%] text-[#1c7573] font-medium text-[16px]">Téléphone fixe :*</label>
//                     <input
//                       type="text"
//                       name="telephone_fixe"
//                       value={formData.telephone_fixe || ''}
//                       onChange={handleChange}
//                       className="px-2 py-2 border border-gray-300 rounded-lg w-full"
//                     />
//                   </div>
//                   <div className="flex flex-col sm:flex-row items-left space-x-1">
//                     <label className="w-full sm:w-[40%] text-[#1c7573] font-medium text-[16px]">Mobile :*</label>
//                     <input
//                       type="text"
//                       name="telephone_mobile"
//                       value={formData.telephone_mobile || ''}
//                       onChange={handleChange}
//                       className="px-2 py-2 border border-gray-300 rounded-lg w-full"
//                     />
//                   </div>
//                 </div>
//                 <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
//                   <label className="w-auto text-[#1c7573] font-medium text-[16px]">Mail :*</label>
//                   <input
//                     type="email"
//                     name="email"
//                     value={formData.email || ''}
//                     onChange={handleChange}
//                     className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[60%]"
//                   />
//                 </div>

//                 {formData.autoConsommation && (
//                   <>
//                     <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
//                       <label className="w-auto text-[#1c7573] font-medium text-[16px]">CIN :*</label>
//                       <input
//                            type="file"
//                            name="cin"
//                            id="cin"
//                            accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
//                            onChange={(e) => handleFileChange(e, 'cin')}
//                            className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
//                            required={formData.autoConsommation}
//                            />
//                     </div>
//                     <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
//                       <label className="w-auto text-[#1c7573] font-medium text-[16px]">Nom du bénéficiaire :*</label>
//                       <input
//                         type="text"
//                         name="nom_beneficiaire"
//                         value={formData.nom_beneficiaire || ''}
//                         onChange={handleChange}
//                         className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[60%]"
//                         required={formData.autoConsommation}
//                       />
//                     </div>
//                   </>
//                 )}
//               </div>
//             </>
//           )}

//           <div className="flex justify-center">
//             <button
//               type="submit"
//               className="w-full md:w-[26%] py-3 sm:py-3 md:py-4 lg:py-4 xl:py-4 2xl:py-6 px-3 bg-[#006462] text-[#ffdf76] font-normal text-2xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-4xl rounded-3xl"
//               style={{ borderRadius: '50px' }}
//             >
//               ENREGISTRER
//             </button>
//           </div>
//         </form>
//       </div>
//       {showModal && modalContent === '' && (
//     <Modal onClose={() => setShowModal(false)}>
//         <div className="bg-white p-6 rounded shadow-lg">
//             <h2 className="text-xl font-semibold mb-4">Télécharger des fichiers</h2>

//             <div className="mb-4">
//                 <label htmlFor="copie-facture" className="block mb-2">Copie facture électricité</label>
//                 <input
//                     type="file"
//                     name="copie-facture"
//                     onChange={(e) => handleFileChange(e, 'copieFacture')}
//                     id="copie-facture"
//                     className="block w-full"
//                 />
//             </div>

//             <div className="mb-4">
//                 <label htmlFor="propriete" className="block mb-2">Propriété</label>
//                 <input
//                     type="file"
//                     name="propriete"
//                     onChange={(e) => handleFileChange(e, 'propriete')}
//                     id="propriete"
//                     className="block w-full"
//                 />
//             </div>

//             <div className="mb-4">
//                 <label htmlFor="multi-files" className="block mb-2">Dessins, schéma, Photo</label>
//                 <input
//                     type="file"
//                     name="dessins_schema_photo"
//                     multiple
//                     onChange={(e) => handleFileChange(e, 'dessinsSchemaPhoto', true)}
//                     id="dessins_schema_photo"
//                     className="block w-full"
//                 />
//             </div>

//             <div className="mb-4">
//                 <label htmlFor="photos-docs" className="block mb-2">Photos, autres docs</label>
//                 <input
//                     type="file"
//                     name="photos_autres_docs"
//                     multiple
//                     onChange={(e) => handleFileChange(e, 'photosAutresDocs', true)}
//                     id="photos_autres_docs"
//                     className="block w-full"
//                 />
//             </div>

//             {Object.values(files).some(file => file && (Array.isArray(file) ? file.length > 0 : true)) && (
//                 <div>
//                     <h3>Fichiers sélectionnés :</h3>
//                     <ul>
//                         {files.copieFacture && <li>{files.copieFacture.name}</li>}
//                         {files.propriete && <li>{files.propriete.name}</li>}
//                         {files.dessinsSchemaPhoto.map((file, index) => (
//                             <li key={index}>{file.name}</li>
//                         ))}
//                         {files.photosAutresDocs.map((file, index) => (
//                             <li key={index}>{file.name}</li>
//                         ))}
//                     </ul>
//                 </div>
//             )}

//             <div className="mt-4 flex justify-between">
//                 <button
//                     className="py-2 px-4 bg-[#006462] text-white rounded"
//                     onClick={handleModalOk}
//                 >
//                     Ok
//                 </button>
//             </div>
//         </div>
//     </Modal>
// )}

// {/* Other modal cases */}
// {showModal && modalContent !== '' && (
//     <Modal onClose={() => setShowModal(false)}>
//         {renderModalContent()}
//     </Modal>
// )}


//       <Footer />
//     </div>
//   );
// };

// export default Demande2;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../demandeSlice';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Modal from '../components/modal';
import { useLocation } from 'react-router-dom';

const Demande2 = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.demandes.formData);
  const location = useLocation();

  // Access files passed from Demande1
  const { kbisEntrepriseFile, mandatSpecialFile, documentProprieteFile } = location.state || {};

  const [files, setFiles] = useState({
    copieFacture: null,
    propriete: documentProprieteFile || null,
    dessinsSchemaPhoto: [],
    photosAutresDocs: [],
    mandat_special: mandatSpecialFile || null,
    kbis_entreprise: kbisEntrepriseFile || null,
    document_propriete: documentProprieteFile || null,
    cin: null, 
  });

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [signupData, setSignupData] = useState({
    entreprise: '',
    username: '',
    Adresse: '',
    Complementd_adresse: '',
    CP: '',
    Ville: '',
    email: '',
    tele: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let processedValue = value;

    if (type === 'checkbox') {
        processedValue = checked ? 1 : 0; // Convert to 1 or 0
    }

    if (name === 'installation') {
        // Set installation_photovoltaique based on the selected value
        dispatch(updateFormData({
            [name]: value,
            installation_photovoltaique: value === 'oui' ? 1 : 0, // Set 1 for 'oui', 0 for 'non'
        }));
    } else {
        dispatch(updateFormData({
            [name]: processedValue,
        }));
    }
};

  const handleFileChange = (event, field, multiple = false) => {
    const selectedFiles = multiple ? Array.from(event.target.files) : event.target.files[0];
    setFiles((prevFiles) => ({
      ...prevFiles,
      [field]: multiple ? [...prevFiles[field], ...selectedFiles] : selectedFiles,
    }));
  };

  const handleModalOk = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.autoConsommation) {
        if (!files.cin) {
            alert('Le champ CIN est requis.');
            return;
        }
        if (!formData.nom_beneficiaire) {
            alert('Le champ Nom du bénéficiaire est requis.');
            return;
        }
    }

    const token = localStorage.getItem('token');
    if (!token) {
      setModalContent('signup');
      setShowModal(true);
      return;
    }

    await submitDemande(token);
  };

  const submitDemande = async (token) => {
    const formDataToSend = new FormData();
  
    // Ajout du fichier CIN
    if (files.cin) formDataToSend.append('cin', files.cin);
  
    // Append individual files from Demande1
    if (files.kbis_entreprise) formDataToSend.append('kbis_entreprise', files.kbis_entreprise);
    if (files.mandat_special) formDataToSend.append('mandat_special', files.mandat_special);
    if (files.document_propriete) formDataToSend.append('document_propriete', files.document_propriete);
    if (files.copieFacture) formDataToSend.append('copie_facture', files.copieFacture);
    if (files.propriete) formDataToSend.append('propriete', files.propriete);
  
    // Append multiple files from the modal
    if (files.dessinsSchemaPhoto.length > 0) {
      files.dessinsSchemaPhoto.forEach((file) => formDataToSend.append('dessins_schema_photo[]', file));
    }
    if (files.photosAutresDocs.length > 0) {
      files.photosAutresDocs.forEach((file) => formDataToSend.append('photos_autres_docs[]', file));
    }
  
    // Append other form data
    const branchementCompteur = [];
    if (formData.monophasé) branchementCompteur.push('Monophasé');
    if (formData.triphasé) branchementCompteur.push('Triphasé');
    formDataToSend.append('branchement_compteur', branchementCompteur.join(','));

    // ensure autoconsommation is sent as 1 or 0
    formDataToSend.append('installation_photovoltaique', formData.installation_photovoltaique);

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== undefined && formData[key] !== null && formData[key] !== '') {
        formDataToSend.append(key, formData[key].toString());
      }
    });
  
    try {
      // const response = await fetch('http://localhost:8000/api/demande/add', {
            const response = await fetch('https://www.lampenergie.fr/admin/public/api/demande/add', {

        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token ? `Bearer ${token}` : '',
        },
        body: formDataToSend,
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Validation errors:', errorData.errors || errorData.message || 'Unknown error occurred');
        setModalContent('error');
      } else {
        const data = await response.json();
        console.log('Success:', data);
        setModalContent('success');
      }
    } catch (error) {
      console.error('Error:', error.message || error);
      setModalContent('error');
    }
    setShowModal(true);
  };
  
  const handleSignupSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:8000/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entreprise: signupData.entreprise,
          username: signupData.username,
          Adresse: signupData.Adresse,
          Complementd_adresse: signupData.Complementd_adresse,
          CP: signupData.CP,
          Ville: signupData.Ville,
          email: signupData.email,
          tele: signupData.tele,
          password: signupData.password,
          role: 'installateur', 
        }),
      });

      const data = await response.json();
      console.log('Fichiers enregistrés:', data.files); 

      if (!response.ok) {
        const contentType = response.headers.get('content-type');
        let errorData;

        if (contentType && contentType.includes('application/json')) {
          errorData = await response.json();
        } else {
          errorData = { message: 'Unexpected response format from server' };
        }

        console.error('Signup errors:', errorData.errors || errorData.message || 'Unknown error occurred');
        setModalContent('error');
      } else {
        const data = await response.json();
        localStorage.setItem('token', data.token); 
        await submitDemande(data.token); 
      }
    } catch (error) {
      console.error('Error:', error.message || error);
      setModalContent('error');
    }
  };

  const handleModalClose = () => {
    setShowModal(false);

    // Réinitialiser les champs du formulaire
    dispatch(updateFormData({
        ref_cadastrale_section: '',
        ref_cadastrale_parcelle: '',
        surface_totale_parcelle: '',
        coordonnees_gps: '',
        monophasé: false,
        triphasé: false,
        installation: 'non',
        nom_projet:'',
        date_mes: '',
        numero_crae: '',
        marque_ancien_onduleur: '',
        modele_ancien_onduleur: '',
        puissance_ancienne_installation: '',
        nom: '',
        prenom: '',
        date_de_naissance: '',
        lieu_de_naissance: '',
        pays: '',
        adresse: '',
        complement_adresse: '',
        cp: '',
        ville: '',
        telephone_fixe: '',
        telephone_mobile: '',
        email: '',
        autoConsommation: false,
        nom_beneficiaire: '',
    }));

    // Réinitialiser les fichiers
    setFiles({
        copieFacture: null,
        propriete: null,
        dessinsSchemaPhoto: [],
        photosAutresDocs: [],
        mandat_special: null,
        kbis_entreprise: null,
        document_propriete: null,
        cin: null,
    });
  };

  const renderModalContent = () => {
    switch (modalContent) {
        case 'success':
            return (
                <div className="bg-white p-6 rounded shadow-lg">
                    <h2 className="text-xl font-semibold mb-4">Enregistrement réussi</h2>
                    <p>Votre demande a été enregistrée avec succès.</p>
                    <button
                        className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
                        onClick={handleModalClose}
                    >
                        Fermer
                    </button>
                </div>
            );
        case 'error':
            return (
                <div className="bg-white p-6 rounded shadow-lg">
                    <h2 className="text-xl font-semibold mb-4">Erreur</h2>
                    <p>Une erreur est survenue lors de l'enregistrement. Veuillez vérifier les champs et réessayer.</p>
                    <button
                        className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
                        onClick={() => setShowModal(false)}
                    >
                        Fermer
                    </button>
                </div>
            );
        case 'signup':
            return (
                <div className="bg-white p-6 rounded shadow-lg">
                    <h2 className="text-xl font-semibold mb-4">Connexion requise</h2>
                    <p>Vous devez être connecté pour soumettre ce formulaire. Veuillez vous inscrire ou vous connecter.</p>
                    <form onSubmit={handleSignupSubmit}>
                        <input
                            type="text"
                            name="entreprise"
                            placeholder="Entreprise"
                            value={signupData.entreprise}
                            onChange={handleChange}
                            className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
                        />
                        <input
                            type="text"
                            name="username"
                            placeholder="Nom du dirigeant"
                            value={signupData.username}
                            onChange={handleChange}
                            className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
                        />
                        <input
                            type="text"
                            name="Adresse"
                            placeholder="Adresse"
                            value={signupData.Adresse}
                            onChange={handleChange}
                            className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
                        />
                        <input
                            type="text"
                            name="Complementd_adresse"
                            placeholder="Complément d’adresse"
                            value={signupData.Complementd_adresse}
                            onChange={handleChange}
                            className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
                        />
                        <input
                            type="number"
                            name="CP"
                            placeholder="CP"
                            value={signupData.CP}
                            onChange={handleChange}
                            className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
                        />
                        <input
                            type="text"
                            name="Ville"
                            placeholder="Ville"
                            value={signupData.Ville}
                            onChange={handleChange}
                            className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={signupData.email}
                            onChange={handleChange}
                            className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
                        />
                        <input
                            type="text"
                            name="tele"
                            placeholder="Téléphone"
                            value={signupData.tele}
                            onChange={handleChange}
                            className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Mot de passe"
                            value={signupData.password}
                            onChange={handleChange}
                            className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
                        />
                        <button
                            type="submit"
                            className="mt-4 py-2 px-4 bg-[#006462] text-white rounded w-full"
                        >
                            S'inscrire / Se connecter
                        </button>
                    </form>
                </div>
            );
        default:
            return null;
    }
  };

  return (
    <div>
      <Header />
      <div className="bg-white p-4 lg:mx-12">
        <form onSubmit={handleSubmit} className="space-y-6">
          <h2 className="2xl:text-3xl text-[25px] font-medium text-[#616565]">Références cadastrales du terrain :</h2>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-0 px-2">
            <div className="flex flex-col lg:flex-row items-center gap-2">
              <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[30%] 2xl:text-end md:w-[100%]">
                N° de section :
              </label>
              <input
                type="number"
                name="ref_cadastrale_section"
                value={formData.ref_cadastrale_section || ''}
                onChange={handleChange}
                className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[50%] md:w-[100%]"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-2 ">
              <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[30%] 2xl:text-end md:w-[100%]">
                N° de parcelle :
              </label>
              <input
                type="number"
                name="ref_cadastrale_parcelle"
                value={formData.ref_cadastrale_parcelle || ''}
                onChange={handleChange}
                className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[50%] md:w-[100%]"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-2 xl:gap-1">
              <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[80%] 2xl:text-end">
                Surface totale :
              </label>
              <input
                type="number"
                name="surface_totale_parcelle"
                value={formData.surface_totale_parcelle || ''}
                onChange={handleChange}
                className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[60%]"
              />
              <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[40%]">
                M<sup>2</sup>
              </label>
            </div>
          </div>

          <div className="flex flex-wrap sm:flex-row w-full">
            <label className="w-full text-[#1c7573] font-medium text-[16px] 2xl:w-[20%] xl:w-[20%] lg:w-[30%] md:w-[30%]">
              Coordonnées GPS ou Google Earth :
            </label>
            <input
              type="text"
              name="coordonnees_gps"
              value={formData.coordonnees_gps || ''}
              onChange={handleChange}
              className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[70%] md:w-[70%] 2xl:w-[80%] xl:w-[80%]"
            />
          </div>

          <h2 className="text-[25px] 2xl:text-2xl font-medium text-[#616565]">
            Éléments de facture EDF du client producteur (ou copie de la facture complète)
          </h2>
          <div className="flex flex-col sm:flex-row items-center space-x-4 sm:ml-">
            <div className="ml-5">
              <label className="text-[#1c7573] text-lg font-semibold md:text-[1.12rem]">
                Copie facture, photos, disjoncteur et compteur :
              </label>
            </div>

               {/* Button to show modal */}
            <div>
              <div
                className="py-3 px-8 bg-[#006462] text-[#ffdf76] font-normal text-2xl rounded-none flex items-center cursor-pointer lg:w-full md:w-[100%] w-[70%]"
                onClick={() => setShowModal(true)}
              >
                Télécharger des fichiers
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row items-baseline space-x-4 ml-5">
            <label className="text-[#1c7573] font-medium">Branchement (compteur) :</label>
            <label className="flex items-center font-semibold text-[#1c7573] space-x-2">
              <span>Monophasé</span>
              <input
                type="checkbox"
                name="monophasé"
                checked={formData.monophasé || false}
                onChange={handleChange}
                className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
              />
            </label>
            <label className="flex items-center font-semibold text-[#1c7573] space-x-2">
              <span>Triphasé</span>
              <input
                type="checkbox"
                name="triphasé"
                checked={formData.triphasé || false}
                onChange={handleChange}
                className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
              />
            </label>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ml-5">
            <div className="flex flex-col sm:flex-row items-left space-x-2">
              <label className="text-[#ff1c1e] italic font-medium">
                Disposez-vous déjà d'une installation photovoltaïque ?
              </label>
              <label className="flex items-center text-[#ff1c1e] italic space-x-2">
                <input
                  type="radio"
                  name="installation"
                  value="non"
                  checked={formData.installation === 'non'}
                  onChange={handleChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <span>NON</span>
              </label>
              <label className="flex items-center text-[#ff1c1e] italic space-x-2">
                <input
                  type="radio"
                  name="installation"
                  value="oui"
                  checked={formData.installation === 'oui'}
                  onChange={handleChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <span>OUI</span>
              </label>
            </div>
          </div>

          {formData.installation === 'oui' && (
  <>
    {/* Champ obligatoire Nom Projet */}
    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Nom Projet :</label>
      <input
  type="text"
  name="nom_projet"
  placeholder="Project-123456"
  value={formData.nom_projet || ''}
  onChange={handleChange}
  pattern="[a-zA-Z]+-\d{6}"
  required
  className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[50%]"
/>

    </div>

    {/* Champ Date de MES */}
    <div className="flex flex-col sm:flex-row items-left space-x-1 italic font-medium ml-5">
      <div className="flex flex-col sm:flex-row items-center space-x-1">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Date de MES :</label>
        <input
          type="date"
          name="date_mes"
          placeholder='A/M/J'
          value={formData.date_mes || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[40%]"
        />
      </div>
      <div className="flex flex-col sm:flex-row items-center space-x-1 w-full">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">N° de CRAE : 0000</label>
        <input
          type="number"
          name="numero_crae"
          value={formData.numero_crae || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[15%]"
        />
      </div>
    </div>

    {/* Autres champs */}
    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <div className="flex flex-col sm:flex-row items-center space-x-2">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Marque de l'ancien onduleur :</label>
        <input
          type="text"
          name="marque_ancien_onduleur"
          value={formData.marque_ancien_onduleur || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[43%]"
        />
      </div>
      <div className="flex flex-col sm:flex-row items-center space-x-2">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Modèle :</label>
        <input
          type="text"
          name="modele_ancien_onduleur"
          value={formData.modele_ancien_onduleur || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[62%]"
        />
      </div>
    </div>

    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">
        Puissance de l'ancienne installation de production (kWc) :
      </label>
      <input
        type="number"
        placeholder='exp:20.9'
        name="puissance_ancienne_installation"
        value={formData.puissance_ancienne_installation || ''}
        onChange={handleChange}
        className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[16%]"
      />
    </div>
  </>
)}


          {formData.installation === 'non' && (
            <>
              <div className="mb-4">
                <div className="flex flex-col md:flex-row items-left md:items-center">
                  <h2 className="text-[24px] 2xl:text-3xl font-medium text-[#616565]">
                    Informations et coordonnées complètes du client/producteur :
                  </h2>
                  <div className="mt-4 md:mt-0 md:ml-4 flex items-center">
                    <input
                      type="checkbox"
                      name="autoConsommation"
                      checked={formData.autoConsommation || false}
                      onChange={handleChange}
                      className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                    />
                    <label className="text-[#1c7573] font-medium text-[16px]">AUTOCONSOMMATION</label>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 ml-5">
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Nom :*</label>
                  <input
                    type="text"
                    name="nom"
                    value={formData.nom || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
                  />
                  <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Prénom :*</label>
                  <input
                    type="text"
                    name="prenom"
                    value={formData.prenom || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Date de naissance :*</label>
                  <input
                    type="date"
                    placeholder='A/M/J'
                    name="date_de_naissance"
                    value={formData.date_de_naissance || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[18%]"
                  />
                  <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Lieu de naissance :*</label>
                  <input
                    type="text"
                    name="lieu_de_naissance"
                    value={formData.lieu_de_naissance || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Pays :*</label>
                  <input
                    type="text"
                    name="pays"
                    value={formData.pays || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Adresse :*</label>
                  <input
                    type="text"
                    name="adresse"
                    value={formData.adresse || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[57%]"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Complément d'adresse :</label>
                  <input
                    type="text"
                    name="complement_adresse"
                    value={formData.complement_adresse || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[49.5%]"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">CP :*</label>
                  <input
                    type="number"
                    name="cp"
                    value={formData.cp || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[18%]"
                  />
                  <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Ville :*</label>
                  <input
                    type="text"
                    name="ville"
                    value={formData.ville || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[45.5%]"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1">
                  <div className="flex flex-col sm:flex-row items-center space-x-1 gap-4">
                    <label className="w-full sm:w-[70%] text-[#1c7573] font-medium text-[16px]">Téléphone fixe :*</label>
                    <input
                      type="text"
                      name="telephone_fixe"
                      value={formData.telephone_fixe || ''}
                      onChange={handleChange}
                      className="px-2 py-2 border border-gray-300 rounded-lg w-full"
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row items-left space-x-1">
                    <label className="w-full sm:w-[40%] text-[#1c7573] font-medium text-[16px]">Mobile :*</label>
                    <input
                      type="text"
                      name="telephone_mobile"
                      value={formData.telephone_mobile || ''}
                      onChange={handleChange}
                      className="px-2 py-2 border border-gray-300 rounded-lg w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Mail :*</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[60%]"
                  />
                </div>

                {formData.autoConsommation === 1 && (
                  <>
                    <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                      <label className="w-auto text-[#1c7573] font-medium text-[16px]">CIN :*</label>
                      <input
                           type="file"
                           name="cin"
                           id="cin"
                           accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
                           onChange={(e) => handleFileChange(e, 'cin')}
                           className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                           required={formData.autoConsommation}
                           />
                    </div>
                    <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                      <label className="w-auto text-[#1c7573] font-medium text-[16px]">Nom du bénéficiaire :*</label>
                      <input
                        type="text"
                        name="nom_beneficiaire"
                        value={formData.nom_beneficiaire || ''}
                        onChange={handleChange}
                        className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[60%]"
                        required={formData.autoConsommation}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          <div className="flex justify-center">
            <button
              type="submit"
              className="w-full md:w-[26%] py-3 sm:py-3 md:py-4 lg:py-4 xl:py-4 2xl:py-6 px-3 bg-[#006462] text-[#ffdf76] font-normal text-2xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-4xl rounded-3xl"
              style={{ borderRadius: '50px' }}
            >
              ENREGISTRER
            </button>
          </div>
        </form>
      </div>
      {showModal && modalContent === '' && (
    <Modal onClose={() => setShowModal(false)}>
        <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Télécharger des fichiers</h2>

            <div className="mb-4">
                <label htmlFor="copie-facture" className="block mb-2">Copie facture électricité</label>
                <input
                    type="file"
                    name="copie-facture"
                    onChange={(e) => handleFileChange(e, 'copieFacture')}
                    id="copie-facture"
                    className="block w-full"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="propriete" className="block mb-2">Propriété</label>
                <input
                    type="file"
                    name="propriete"
                    onChange={(e) => handleFileChange(e, 'propriete')}
                    id="propriete"
                    className="block w-full"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="multi-files" className="block mb-2">Dessins, schéma, Photo</label>
                <input
                    type="file"
                    name="dessins_schema_photo"
                    multiple
                    onChange={(e) => handleFileChange(e, 'dessinsSchemaPhoto', true)}
                    id="dessins_schema_photo"
                    className="block w-full"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="photos-docs" className="block mb-2">Photos, autres docs</label>
                <input
                    type="file"
                    name="photos_autres_docs"
                    multiple
                    onChange={(e) => handleFileChange(e, 'photosAutresDocs', true)}
                    id="photos_autres_docs"
                    className="block w-full"
                />
            </div>

            {Object.values(files).some(file => file && (Array.isArray(file) ? file.length > 0 : true)) && (
                <div>
                    <h3>Fichiers sélectionnés :</h3>
                    <ul>
                        {files.copieFacture && <li>{files.copieFacture.name}</li>}
                        {files.propriete && <li>{files.propriete.name}</li>}
                        {files.dessinsSchemaPhoto.map((file, index) => (
                            <li key={index}>{file.name}</li>
                        ))}
                        {files.photosAutresDocs.map((file, index) => (
                            <li key={index}>{file.name}</li>
                        ))}
                    </ul>
                </div>
            )}

            <div className="mt-4 flex justify-between">
                <button
                    className="py-2 px-4 bg-[#006462] text-white rounded"
                    onClick={handleModalOk}
                >
                    Ok
                </button>
            </div>
        </div>
    </Modal>
)}

{/* Other modal cases */}
{showModal && modalContent !== '' && (
    <Modal onClose={() => setShowModal(false)}>
        {renderModalContent()}
    </Modal>
)}


      <Footer />
    </div>
  );
};

export default Demande2;

