import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for submitting the form data
export const envoyerDemande = createAsyncThunk(
  'demandes/envoyerDemande',
  async (formDataToSend, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      // const response = await axios.post('http://localhost:8000/api/demande/add', formDataToSend, {
            const response = await axios.post('https://www.lampenergie.fr/admin/public/api/demande/add', formDataToSend, {

        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  formData: {
    type_client: '',
    installateur_email: '',
    installateur_telephone: '',
    nom_entreprise: '',
    numero_siret: '',
    type_activite: '',
    entreprise_email: '',
    entreprise_telephone: '',
    prestations_souhaitees: '',
    puissance_prevue: '',
    type_installation: '',
    type_production: '',
    marques_modeles_panneaux_onduleurs: '',
    destination_batiment: '',
    precisions_destination: '',
    adresse_production: '',
    complement_adresse_production: '',
    ville_production: '',
    cp_production: '',
    date_mes: '',
    ref_cadastrale_section: '',
    ref_cadastrale_parcelle: '',
    surface_totale_parcelle: '',
    coordonnees_gps: '',
    monophasé: false,
    triphasé: false,
    installation: 'non',
    numero_crae: '',
    marque_ancien_onduleur: '',
    modele_ancien_onduleur: '',
    puissance_ancienne_installation: '',
    nom: '',
    prenom: '',
    date_de_naissance: '',
    lieu_de_naissance: '',
    pays: '',
    adresse: '',
    complement_adresse: '',
    cp: '',
    ville: '',
    telephone_fixe: '',
    telephone_mobile: '',
    email: '',
    nom_beneficiaire: '',
    autoConsommation: false,
  },
  demandes: [],
  loading: false,
  error: null,
};

const demandeSlice = createSlice({
  name: 'demandes',
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = initialState.formData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(envoyerDemande.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(envoyerDemande.fulfilled, (state, action) => {
        state.loading = false;
        state.demandes.push(action.payload);
        state.error = null;
      })
      .addCase(envoyerDemande.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { updateFormData, resetFormData } = demandeSlice.actions;

export default demandeSlice.reducer;
