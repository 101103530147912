import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import valide from '../img/ico-valid.png';
import non from '../img/ico-non.png';
import vorange from '../img/ico-Vorange.png';
import iconSearch from '../img/ico-loupe.png';
import icon from '../img/icone-debuttexte.png';
import StyledButton from '../components/styleButton';

const Dashboard = () => {
  const [demandes, setDemandes] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
                const response = await fetch('https://www.lampenergie.fr/admin/public/api/user-demandes', {

        // const response = await fetch('http://localhost:8000/api/user-demandes', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setDemandes(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [token]);

  const renderStatusIcon = (status) => {
    switch (status) {
      case 'traiter':
        return <img src={valide} alt="Valide" className="w-6 h-6 inline-block" />;
      case 'en cours':
        return <img src={vorange} alt="En cours" className="w-6 h-6 inline-block" />;
      case 'non traite':
        return <img src={non} alt="Non traité" className="w-6 h-6 inline-block" />;
      default:
        return null;
    }
  };

  const renderStatuIcon = (value) => {
    if (value) {
      return <img src={valide} alt="Valide" className="w-6 h-6 inline-block" />;
    } else {
      return <img src={non} alt="Non traité" className="w-6 h-6 inline-block" />;
    }
  };

  const renderStatuPIcon = (status) => {
    switch (status) {
      case 'traiter':
        return <img src={valide} alt="Valide" className="w-6 h-6 inline-block" />;
      case 'en cours':
        return <img src={vorange} alt="En cours" className="w-6 h-6 inline-block" />;
      case 'non traite':
        return <img src={non} alt="Non traité" className="w-6 h-6 inline-block" />;
      default:
        return null;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/installer-form-login');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/mes-informations');
  };

  const handleArchives = (e) => {
    e.preventDefault();
    navigate('/archives');
  };

  const handleCompte = (e) => {
    e.preventDefault();
    navigate('/mon-compte');
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (demandes.length === 0) {
  //   return <div>No data found.</div>;
  // }

  return (
    <div>
      <Header />
      <div className="w-full h-full mx-auto px-4 md:px-4 lg:px-16 xl:px-20 2xl:px-20">
        <div className="flex flex-col sm:flex-col md:justify-between lg:justify-between xl:justify-between md:flex-row lg:flex-row xl:flex-row 2xl:flex-row lg:items-center mb-4">
          <div>
            <div className="flex items-center">
              <img src={icon} alt="icon" className="inline-block w-6 h-6 mx-2" />
              <h2 className="text-black text-xl font-normal hover:underline" onClick={handleCompte}>
                Dossiers en cours
              </h2>
            </div>
            <ul className="mt-2 ml-4">
              <li className="mb-1">
                <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleArchives}>
                  Dossiers archivés
                </a>
              </li>
              <li className="mb-1">
                <a className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleSubmit}>
                  Mes informations
                </a>
              </li>
              <li className="mb-1">
                <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleLogout}>
                  Déconnexion
                </a>
              </li>
            </ul>
          </div>
          
          <StyledButton type="button" onClick={() => navigate('/creation-demande')}>
NOUVELLE DEMANDE</StyledButton>
        </div>
        <div className="mb-4">
          <div className="flex items-center p-2 border rounded-lg 2xl:w-[34%] xl:w-[34%] lg:w-[34%] md:w-[34%]">
            <input
              type="text"
              placeholder="RECHERCHE DE DOSSIERS"
              className="flex-grow p-2 focus:outline-none"
            />
            <img src={iconSearch} alt="Search" className="w-6 h-6 ml-2" />
          </div>
        </div>

        {demandes.map((demande, index) => (
          <div key={index} className="w-[100%] mb-14">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
              {/* Gestion des documents */}
              <div className="bg-[#d9d8d8]">
                <div className="p-3 bg-[#c3dddc]">
                  <h1 className="text-xl font-normal text-black flex justify-start">{demande.nom_projet || demande.nom}</h1>
                </div>
                <div className="bg-[#d9d8d8] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] lg:font-bold font-bold mb-8 p-2 text-[#1c7573] underline">Gestion des documents:</h3>
                  {/* Fiche informations */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Fiche informations</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.fiche_info)}
                        {!demande.fiche_info && (
                          <button className="text-red-500 font-bold underline text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">
                            Télécharger
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Kbis Entreprise */}
                  {demande.type_client === 'professionnel' && (
                    <div className="flex flex-row mb-2 w-[100%] gap-2">
                      <div className="w-1/2">
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Kbis Entreprise</p>
                      </div>
                      <div className="w-1/2">
                        <div className="flex flex-row items-center gap-2">
                          {renderStatuIcon(demande.kbis_entreprise)}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Mandat */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Mandat</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.mandat_special)}
                        {!demande.mandat_special && (
                          <button className="text-red-500 font-bold underline text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">
                            Télécharger
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Dessins, schéma, Photo */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Dessins, schéma, Photo</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.dessins_schema_photo)}
                        {!demande.dessins_schema_photo && (
                          <button className="text-red-500 font-bold underline text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">
                            Télécharger
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Copie facture électricité */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Copie facture électricité</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.copie_facture)}
                        {!demande.copie_facture && (
                          <button className="text-red-500 font-bold underline text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">
                            Télécharger
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Propriété */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Propriété</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.propriete)}
                        {!demande.propriete && (
                          <button className="text-red-500 font-bold underline text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">
                            Télécharger
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Photos, autres docs */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Photos, autres docs</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.photos_autres_docs)}
                        {!demande.photos_autres_docs && (
                          <button className="text-red-500 font-bold underline text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">
                            Télécharger
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Voir les Informations */}
              <div className="bg-[#eeebeb]">
                <div className="p-3 bg-[#1c7573]">
                  <h1 className="text-xl font-normal text-center text-[#ffdf76]">Voir les Informations</h1>
                </div>
                <div className="bg-[#d9d8d8] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] lg:font-bold font-bold mb-3 p-2 text-[#1c7573]">
                    DP Urbanisme<span className="text-black font-normal">- Avancement :</span>
                  </h3>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/4">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date dépôt:</p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.dp_date_depot_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_date_depot}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_date_depot_text}</p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-2 w-[100%] gap-2 -ml-8">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Délai :</p>
                    </div>
                    <div className="w-[67%]">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.dp_delai_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_delai}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center justify-start pl-11">
                    {renderStatuPIcon (demande.dp_piece_statut)}               
                    <div className="ml-1 text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
                    <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_piece}</p>
                  </div>
                </div>

                <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] lg:font-bold font-bold mb-3 p-2 text-[#1c7573]">
                    ENEDIS ou Régle<span className="text-black font-normal">- Avancement :</span>
                  </h3>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date MES:</p>
                    </div>
                    <div className="w-[67%]">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.enedis_date_mes_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_date_mes}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_date_mes_text}</p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date PCR:</p>
                    </div>
                    <div className="w-[67%]">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.enedis_pcr_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_pcr}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_date_pcr_text}</p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center justify-start pl-11">
                    {renderStatuPIcon(demande.enedis_piece_statut)}               
                    <div className="ml-1 text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
                    <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_piece}</p>
                  </div>
                </div>
              </div>

              {/* Dossier traité */}
              <div className="bg-[#eeebeb]">
                <div className="p-3 bg-[#c3dddc]">
                  <h1 className="text-xl font-normal text-[#1c7573] flex justify-end pr-10">Dossier traité à : <span className="font-bold">{demande.dossier_traite}</span></h1>
                </div>
                <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] font-bold mb-3 p-2 text-[#1c7573]">
                    CONSUEL<span className="text-black font-normal">- Avancement :</span>
                  </h3>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date complétude:</p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.consuel_date_completude_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_completude}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_completude_text}</p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date Visa:</p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.consuel_date_visa_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_visa}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_visa_text}</p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center justify-start pl-11">
                    {renderStatuPIcon (demande.consuel_piece_statut)}               
                    <div className="ml-1 text-[14px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
                    <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_piece}</p>
                  </div>
                </div>


                <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] font-bold mb-3 p-2 text-[#1c7573]">
                  DOE (Option si PRO)<span className="text-black font-normal">- Avancement :</span>
                  </h3>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Rapport:
                      </p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.doe_rapport_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_rapport}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_rapport_text}</p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Envoyé/encours:</p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.doe_envoye_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_envoye}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_envoi_text}</p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center justify-start pl-11">
                    {renderStatuPIcon (demande.doe_piece_statut)}               
                    <div className="ml-1 text-[14px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
                    <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_piece}</p>
                  </div>
                </div>

              </div>
            </div>

            {/* EDF OA OPTION */}
            <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
              <h3 className="text-md font-bold mb-3 p-2 text-[#1c7573] lg:text-[1rem]">EDF OA OPTION<span className="text-black font-normal">- Avancement :</span></h3>
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-2">
                <div className="flex flex-row justify-center sm:justify-center md:gap-12 lg:gap-12 xl:gap-2 2xl:gap-2">
                  <div className="flex justify-center items-center">
                    <p className="flex justify-end text-[13px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[14px] 2xl:text-[15px]">Date création du portail:</p>
                    <div className="flex flex-row items-center gap-2">
                      {renderStatusIcon(demande.edf_date_statut)}
                      <p className="flex justify-end text-[12px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[15px] 2xl:text-[16px]">{demande.edf_date}:</p>
                    </div>
                  </div>
                  <div className="hidden sm:hidden md:hidden lg:block xl:block 2xl:block"></div>
                  <div className="">
                    <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.edf_date_creation_text}</p>
                  </div>
                </div>

                <div className="flex flex-row justify-center sm:justify-center md:gap-12 lg:gap-12 xl:gap-14 2xl:gap-2">
                  <div className="flex justify-center items-center">
                    <p className="flex justify-end text-[13px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[15px] 2xl:text-[16px]">Codes / 1ere facturation:</p>
                    <div className="flex flex-row items-center gap-2">
                      {renderStatusIcon(demande.edf_code_statut)}
                      <p className="flex justify-end text-[12px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[15px] 2xl:text-[16px]">{demande.edf_code}</p>
                    </div>
                  </div>
                  <div className="hidden sm:hidden md:hidden lg:block xl:block 2xl:block"></div>
                  <div className="">
                    <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.edf_codes_text}</p>
                  </div>
                </div>

                <div className="flex gap-2 items-center justify-center pl-11">
                  {renderStatuPIcon(demande.edf_piece_statut)}
                  <div className="ml-1 text-[14px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
                  <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.edf_piece}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-16">
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
