import React from 'react';
import '../style/Footer.css';
import phoneIcon from '../img/icotelfooter.png'; 
import emailIcon from '../img/icone-message.png';

const Footer = () => {
  return (
    <footer className="footer ">
          

      <div className="inner">
        <div className="footer-left">
          <h3>Nos bureaux</h3>
          <p>3 Rue du chateau</p>
          <p> 30620 UCHAUD</p>
         
        </div>
        <div className="footer-right">
          <ul>
          <li><a href="/RGPD">RGPD</a></li>
            <li><a href="/CGV-CGU">CGU/CGV</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
          
        </div>
        <div className="footer-center">
          <h3>Nous Contacter</h3>
          <p>
            <img src={phoneIcon} alt="Phone Icon" />{' '}+33 (0)6 01 26 24 06</p>
          <p>
          <img src={emailIcon} alt="Email Icon" />{' '}
            alice.pares@lampenergie.fr</p>
        </div>
      </div> 
      <p>      <div className="yellow-line"></div> {/* Yellow line */}
      </p>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} LAMPENERGIE 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
