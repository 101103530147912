import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logoweb.png';
import hamburger from '../img/hamburger.png';

const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if token exists in localStorage to determine authentication state
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);
  const token = localStorage.getItem('token'); // Check if the user is authenticated

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white border-gray-900 shadow-lg p-3 mb-5 rounded">
      <div className="flex flex-wrap items-center justify-between py-2 2xl:px-20 xl:px-20 lg:px-20">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-10 sm:h-12 md:h-16 lg:h-20 xl:h-24  xl:w-80 2xl:h-20 2xl:w-80 md:w-64 lg:w-60 " alt="Logo" />
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-default"
          aria-expanded={isOpen ? "true" : "false"}
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>

          <img src={hamburger} className="w-5 h-5" alt="Logo" />
          {/* <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg> */}
        </button>
        <div className={`${isOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
          <ul className="md:flex md:justify-center font-medium flex flex-col p-4 md:p-0 mt-4 border rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
            <li>
              <Link to="/" className="block py-2 px-3 text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded md:bg-transparent md:text-[#2e754f] md:p-0 text-black underline" aria-current="page">
                Accueil
              </Link>
            </li>
            {/* <li>
              <Link to="/mon-compte" className="block py-2 px-3 text-[#686868] text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#2e754f] md:p-0 md:dark:hover:text-[#2e754f] dark:hover:bg-gray-700 md:dark:hover:bg-transparent">
                Mon Compte
              </Link>
            </li> */}
           <ul>
          {isAuthenticated ? (
            // If the user is authenticated, show "Mon Compte"
            <li>
              <Link
                to="/mon-compte"
                className="block py-2 px-3 text-[#686868] text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#2e754f] md:p-0 md:dark:hover:text-[#2e754f] dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
              >
                Mon Compte
              </Link>
            </li>
          ) : (
            // If the user is not authenticated, show "Connexion"
            <li>
              <Link
                to="/installer-form-login"
                className="block py-2 px-3 text-[#686868] text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#2e754f] md:p-0 md:dark:hover:text-[#2e754f] dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
              >
                Connexion
              </Link>
            </li>
          )}
        </ul>
            <li>
              <Link to="/info&tarifs" className="block py-2 px-3 text-[#686868] text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#2e754f] md:p-0 dark:hover:bg-gray-700 md:dark:hover:bg-transparent">
                Informations & Tarifs
              </Link>
            </li>
            <li>
              <Link to="/contact" className="block py-2 px-3 text-[#686868] text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#2e754f] md:p-0 md:dark:hover:bg-transparent">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
