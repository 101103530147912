import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateFormData } from '../demandeSlice';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Modal from '../components/modal';
import icon from '../img/icone-debuttexte.png';
import icon1 from '../img/icone-texte-jaune.png';

const Demande1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = useSelector((state) => state.demandes.formData);

  // Local state for files
  const [kbisEntrepriseFile, setKbisEntrepriseFile] = useState(null);
  const [mandatSpecialFile, setMandatSpecialFile] = useState(null);
  const [documentProprieteFile, setDocumentProprieteFile] = useState(null);

  const [showAutreInput, setShowAutreInput] = useState(false);

  // State for modal
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [missingFields, setMissingFields] = useState([]); // New state for missing fields

  // State for form validation errors
  const [errors, setErrors] = useState({});

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    if (id === 'particulier') {
      dispatch(updateFormData({ type_client: 'Particulier' }));
    } else if (id === 'professionnel') {
      dispatch(updateFormData({ type_client: 'Professionnel' }));
    }

    if (id === 'autre' && checked) {
      dispatch(updateFormData({ prestations_souhaitees: 'Autre(s)' }));
      setShowAutreInput(true);
    } else if (checked) {
      dispatch(updateFormData({ prestations_souhaitees: id }));
      setShowAutreInput(false);
    } else {
      dispatch(updateFormData({ prestations_souhaitees: '' }));
      setShowAutreInput(false);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'kbis_entreprise') {
      setKbisEntrepriseFile(files[0]);
    } else if (name === 'mandat_special') {
      setMandatSpecialFile(files[0]);
    } else if (name === 'document_propriete') {
      setDocumentProprieteFile(files[0]);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type, id } = e.target;

    if (type === 'checkbox') {
      if (['venteTotale', 'autoconsommationVente', 'autoconsommationSeule'].includes(name)) {
        dispatch(updateFormData({ type_production: checked ? value : '' }));
      } else if (['surimposeISB', 'integreAuBati'].includes(name)) {
        dispatch(updateFormData({ type_installation: checked ? value : '' }));
      } else if (['destinationHabitation', 'destinationSanteEnseignement', 'destinationAutre'].includes(id)) {
        dispatch(updateFormData({ destination_batiment: checked ? value : '' }));
      }
    } else {
      dispatch(updateFormData({ [name]: value }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'installateur_email',
      'installateur_telephone',
      'type_client',
      'type_installation',
      'puissance_prevue',
      'marques_modeles_panneaux_onduleurs',
      'destination_batiment',
      'prestations_souhaitees',
      'type_production',
    ];

    const missing = requiredFields.filter((field) => !formData[field]);

    missing.forEach((field) => {
      newErrors[field] = 'Ce champ est obligatoire';
    });

    setErrors(newErrors);
    setMissingFields(missing); // Store missing fields in state
    return missing.length === 0;
  };

  const handleNext = () => {
    if (!validateForm()) {
      setModalMessage('Veuillez remplir tous les champs obligatoires suivants :');
      setShowModal(true);
      return;
    }

    // Pass the file objects through navigate's state
    navigate('/creation-demande2', {
      state: {
        kbisEntrepriseFile,
        mandatSpecialFile,
        documentProprieteFile,
      },
    });
  };

  return (
    <div className="w-full h-full overflow-x-hidden">
      <Header />
      <div className="flex flex-col bg-white py-6 px-4 sm:px-6 md:px-12 lg:px-24 xl:px-24 2xl:px-24">
        <p className="self-start text-lg font-medium text-[#616565] md:text-2xl lg:text-3xl">
          <img src={icon} alt="icon" className="inline-block w-6 h-6 align-middle mx-2" /> Formulaire de demande d'étude :
        </p>
        <p className="self-start text-lg text-[#616565] mb-4 md:text-lg lg:px-16 xl:px-16 2xl:px-16 md:px-16">
          Merci de joindre toutes les pièces du dossier. Les questions avec un astérisque sont obligatoires.
        </p>
        <p className="self-start text-[#1c7573] font-medium text-[17px] lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
          Votre chantier concerne une installation pour un client ?{' '}
          <span className="text-[#ff1c1e] italic font-medium text-sm">
            selon réponse : DOC "INFOS A FOURNIR/PRO"
          </span>
        </p>
        <div className="flex justify-center flex-col md:flex-row items-center mt-4 mb-4">
          <div className="flex items-center mr-4">
            <input
              type="checkbox"
              id="particulier"
              name="type_client"
              checked={formData.type_client === 'Particulier'}
              onChange={handleCheckboxChange}
              className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
            />
            <label htmlFor="particulier" className="text-[#070707] font-bold text-[16px]">
              Formulaire Particulier
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="professionnel"
              name="type_client"
              checked={formData.type_client === 'Professionnel'}
              onChange={handleCheckboxChange}
              className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
            />
            <label htmlFor="professionnel" className="text-[#070707] font-bold text-[16px]">
              Formulaire Professionnel
            </label>
          </div>
        </div>

        {/* Formulaire Particulier */}
        {formData.type_client === 'Particulier' && (
          <form className="w-full space-y-6 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="installateur_email" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                E-mail installateur: *
              </label>
              <input
                type="email"
                name="installateur_email"
                id="installateur_email"
                value={formData.installateur_email}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="installateur_telephone" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Téléphone installateur: *
              </label>
              <input
                type="text"
                name="installateur_telephone"
                id="installateur_telephone"
                value={formData.installateur_telephone}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="mandat_special" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Mandat spécial: *
              </label>
              <input
                type="file"
                name="mandat_special"
                id="mandat_special"
                accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
                onChange={handleFileChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Prestations souhaitées: *
              </label>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="Déclaration Préalable"
                  name="prestations_souhaitees"
                  value="Déclaration Préalable"
                  checked={formData.prestations_souhaitees === 'Déclaration Préalable'}
                  onChange={handleCheckboxChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <label htmlFor="Déclaration Préalable" className="text-[#070707] font-semibold text-[16px]">
                  Déclaration Préalable
                </label>
              </div>
              <div className="flex items-center ml-4">
                <input
                  type="checkbox"
                  id="ENEDIS"
                  name="prestations_souhaitees"
                  value="ENEDIS"
                  checked={formData.prestations_souhaitees === 'ENEDIS'}
                  onChange={handleCheckboxChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <label htmlFor="ENEDIS" className="text-[#070707] font-semibold text-[16px]">
                  ENEDIS
                </label>
              </div>
              <div className="flex items-center ml-4">
                <input
                  type="checkbox"
                  id="autre"
                  name="prestations_souhaitees"
                  value="Autre(s)"
                  checked={formData.prestations_souhaitees === 'Autre(s)'}
                  onChange={handleCheckboxChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <label htmlFor="autre" className="text-[#070707] font-semibold text-[16px]">
                  Autre(s)
                </label>
                {showAutreInput && (
                  <input
                    type="text"
                    name="autrePrecision"
                    placeholder="Préciser"
                    value={formData.autrePrecision || ''}
                    onChange={handleChange}
                    className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                  />
                )}
              </div>
            </div>
          </form>
        )}

        {/* Formulaire Professionnel */}
        {formData.type_client === 'Professionnel' && (
          <form className="w-full space-y-6 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="installateur_email" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                E-mail installateur: *
              </label>
              <input
                type="email"
                name="installateur_email"
                id="installateur_email"
                value={formData.installateur_email}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="installateur_telephone" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Téléphone installateur: *
              </label>
              <input
                type="text"
                name="installateur_telephone"
                id="installateur_telephone"
                value={formData.installateur_telephone}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="nom_entreprise" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Nom de l'entreprise installatrice: *
              </label>
              <input
                type="text"
                name="nom_entreprise"
                id="nom_entreprise"
                value={formData.nom_entreprise}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="numero_siret" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Numéro SIRET de l'entreprise: *
              </label>
              <input
                type="text"
                name="numero_siret"
                id="numero_siret"
                value={formData.numero_siret}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="type_activite" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Type d'activité de l'entreprise: *
              </label>
              <input
                type="text"
                name="type_activite"
                id="type_activite"
                value={formData.type_activite}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="entreprise_email" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                E-mail de l'entreprise: *
              </label>
              <input
                type="email"
                name="entreprise_email"
                id="entreprise_email"
                value={formData.entreprise_email}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="entreprise_telephone" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Téléphone de l'entreprise: *
              </label>
              <input
                type="text"
                name="entreprise_telephone"
                id="entreprise_telephone"
                value={formData.entreprise_telephone}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="kbis_entreprise" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Kbis de l'entreprise: *
              </label>
              <input
                type="file"
                name="kbis_entreprise"
                id="kbis_entreprise"
                accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
                onChange={handleFileChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="mandat_special" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Mandat spécial: *
              </label>
              <input
                type="file"
                name="mandat_special"
                id="mandat_special"
                accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
                onChange={handleFileChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Prestations souhaitées: *
              </label>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="Déclaration Préalable"
                  name="prestations_souhaitees"
                  checked={formData.prestations_souhaitees === 'Déclaration Préalable'}
                  onChange={handleCheckboxChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <label htmlFor="Déclaration Préalable" className="text-[#070707] font-semibold text-[16px]">
                  Déclaration Préalable
                </label>
              </div>
              <div className="flex items-center ml-4">
                <input
                  type="checkbox"
                  id="ENEDIS"
                  name="prestations_souhaitees"
                  checked={formData.prestations_souhaitees === 'ENEDIS'}
                  onChange={handleCheckboxChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <label htmlFor="ENEDIS" className="text-[#070707] font-semibold text-[16px]">
                  ENEDIS
                </label>
              </div>
              <div className="flex items-center ml-4">
                <input
                  type="checkbox"
                  id="autre"
                  name="prestations_souhaitees"
                  checked={formData.prestations_souhaitees === 'Autre(s)'}
                  onChange={handleCheckboxChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <label htmlFor="autre" className="text-[#070707] font-semibold text-[16px]">
                  Autre(s)
                </label>
                {showAutreInput && (
                  <input
                    type="text"
                    name="autrePrecision"
                    placeholder="Préciser"
                    value={formData.autrePrecision || ''}
                    onChange={handleChange}
                    className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                  />
                )}
              </div>
            </div>
          </form>
        )}

        {/* Common Sections */}
        <div className="flex flex-col bg-white py-6 px-4 sm:px-6 md:px-12 lg:px-24 xl:px-24 2xl:px-24">
          <p className="self-start text-lg font-medium text-[#616565] md:text-2xl lg:text-3xl mx-4 w-full">
            Informations sur la nouvelle installation :
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <div className="flex items-center gap-2">
              <label htmlFor="surimposeISB" className="text-[#1c7573] font-medium text-[16px]">
                Surimposé/ISB
              </label>
              <input
                type="checkbox"
                id="surimposeISB"
                name="surimposeISB"
                value="Surimposé/ISB"
                checked={formData.type_installation === 'Surimposé/ISB'}
                onChange={handleChange}
                className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
              />
            </div>

            <div className="flex items-center gap-2">
              <label htmlFor="integreAuBati" className="text-[#1c7573] font-medium text-[16px]">
                Intégré au bâti
              </label>
              <input
                type="checkbox"
                id="integreAuBati"
                name="integreAuBati"
                value="Intégré au bâti"
                checked={formData.type_installation === 'Intégré au bâti'}
                onChange={handleChange}
                className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
              />
            </div>

            <div className="flex items-center gap-1">
              <label htmlFor="puissance_prevue" className="w-full md:w-[100%] text-dark font-medium text-[15px]">
                Puissance prévue (kWc): *
              </label>
              <input
                type="number"
                id="puissance_prevue"
                name="puissance_prevue"
                placeholder='exp:10.2'
                value={formData.puissance_prevue}
                onChange={handleChange}
                className="w-full px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <div className="flex items-center gap-2">
              <label htmlFor="venteTotale" className="text-[#1c7573] font-medium text-[16px]">
                Vente totale
              </label>
              <input
                type="checkbox"
                id="venteTotale"
                name="venteTotale"
                value="Vente totale"
                checked={formData.type_production === 'Vente totale'}
                onChange={handleChange}
                className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
              />
            </div>
            <div className="flex items-center gap-2">
              <label htmlFor="autoconsommationVente" className="text-[#1c7573] font-medium text-[16px]">
                Autoconsommation + Vente du surplus
              </label>
              <input
                type="checkbox"
                id="autoconsommationVente"
                name="autoconsommationVente"
                value="Autoconsommation + Vente du surplus"
                checked={formData.type_production === 'Autoconsommation + Vente du surplus'}
                onChange={handleChange}
                className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
              />
            </div>
            <div className="flex items-center gap-2">
              <label htmlFor="autoconsommationSeule" className="text-[#1c7573] font-medium text-[16px]">
                Autoconsommation seule
              </label>
              <input
                type="checkbox"
                id="autoconsommationSeule"
                name="autoconsommationSeule"
                value="Autoconsommation seule"
                checked={formData.type_production === 'Autoconsommation seule'}
                onChange={handleChange}
                className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
              />
            </div>
          </div>

          <div className="mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <label htmlFor="marqueModele" className="block text-[#1c7573] font-medium text-[16px] mb-2">
              Nb marque et modèles panneaux et onduleurs(/batteries): *
            </label>
            <input
              type="text"
              id="marqueModele"
              name="marques_modeles_panneaux_onduleurs"
              value={formData.marques_modeles_panneaux_onduleurs}
              onChange={handleChange}
              className="w-full px-2 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="grid grid-cols-1 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <div>
              <label htmlFor="devis" className="text-[#1c7573] font-medium text-lg mb-2">
                Devis (matériel/fournisseurs) :
              </label>
              <textarea
                id="devis"
                name="devis"
                value={formData.devis}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                rows={6}
                placeholder="Décrivez les détails du devis ici..."
                required
              />
            </div>
          </div>
        </div>

        {/* Informations de production */}
        <div className="flex flex-col bg-white py-6 px-4 sm:px-6 md:px-12 lg:px-24 xl:px-24 2xl:px-24">
          <p className="self-start font-medium text-[#616565] text-lg md:text-2xl lg:text-3xl mx-4 w-full">
            Informations de production :
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <label htmlFor="destination" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
              Destination du bâtiment: *
            </label>
            <div className="col-span-2 items-center grid grid-cols-1 2xl:grid-cols-3 xl:grid-cols-3">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="destinationHabitation"
                  name="destination_batiment"
                  value="Habitation"
                  checked={formData.destination_batiment === 'Habitation'}
                  onChange={handleChange}
                  className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <label htmlFor="destinationHabitation" className="font-normal text-[#1c7573]">
                  Habitation
                </label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="destinationSanteEnseignement"
                  name="destination_batiment"
                  value="Santé/enseignement"
                  checked={formData.destination_batiment === 'Santé/enseignement'}
                  onChange={handleChange}
                  className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <label htmlFor="destinationSanteEnseignement" className="font-normal text-[#1c7573]">
                  Santé/enseignement
                </label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="destinationAutre"
                  name="destination_batiment"
                  value="Autre"
                  checked={formData.destination_batiment === 'Autre'}
                  onChange={handleChange}
                  className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <label htmlFor="destinationAutre" className="font-normal text-[#1c7573]">
                  Autre
                </label>
              </div>

              {formData.destination_batiment === 'Autre' && (
                <div className="mt-4">
                  <label htmlFor="autreDestination" className="text-[#1c7573] font-medium mb-2">
                    Précisez l'autre destination :
                  </label>
                  <input
                    type="text"
                    id="autreDestination"
                    name="precisions_destination"
                    value={formData.precisions_destination}
                    onChange={handleChange}
                    className="col-span-2 px-2 py-2 border border-gray-300 rounded-lg"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <label htmlFor="adresse" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
              Adresse: *
            </label>
            <input
              type="text"
              name="adresse_production"
              id="adresse"
              value={formData.adresse_production}
              onChange={handleChange}
              className="col-span-2 px-2 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <label htmlFor="complementAdresse" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
              Complement d'adresse: 
            </label>
            <input
              type="text"
              name="complement_adresse_production"
              id="complementAdresse"
              value={formData.complement_adresse_production}
              onChange={handleChange}
              className="col-span-2 px-2 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <label htmlFor="document_propriete" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
              Documents Propriété: 
            </label>
            <input
              type="file"
              name="document_propriete"
              id="document_propriete"
              accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
              onChange={handleFileChange}
              className="col-span-2 px-2 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <div className="flex flex-col">
              <label htmlFor="cp" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
                CP: *
              </label>
              <input
                type="number"
                id="cp"
                name="cp_production"
                value={formData.cp_production}
                onChange={handleChange}
                className="w-full px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div className="flex flex-col lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
              <label htmlFor="ville" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
                Ville: *
              </label>
              <input
                type="text"
                id="ville"
                name="ville_production"
                value={formData.ville_production}
                onChange={handleChange}
                className="w-full px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <button
              type="button"
              className="py-2 px-8 bg-[#006462] text-[#ffdf76] font-normal text-xl rounded-full flex items-center"
              onClick={handleNext}
            >
              SUIVANT
              <img src={icon1} alt="icon" className="inline-block w-8 h-8 align-middle ml-2" />
            </button>
          </div>
        </div>
      </div>
      <Footer />
      {/* Modal for missing fields */}
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Champs obligatoires manquants</h2>
            <p>{modalMessage}</p>
            <ul className="list-disc pl-6">
              {missingFields.map((field, index) => (
                <li key={index}>{field}</li>
              ))}
            </ul>
            <button
              className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
              onClick={() => setShowModal(false)}
            >
              Fermer
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Demande1;
