import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import image from '../img/deco-bas de page.png'; 

const ContactForm = () => {
    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        tel: '',
        sujet: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Ajouter votre logique de soumission de formulaire ici
        console.log('Données du formulaire soumises :', formData);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow flex flex-col">
                <div className="container mx-auto px-4 py-8">
                    <div className="text-center mb-8">
                        <i className="fas fa-envelope text-6xl text-teal-700"></i>
                    </div>
                    <form onSubmit={handleSubmit} className="max-w-4xl mx-auto p-10 rounded-lg shadow-lg mb-48">
                        <div className="grid gap-6">
                            <div>
                                <label htmlFor="nom" className="block text-gray-700 font-medium text-xl">Nom</label>
                                <input
                                    type="text"
                                    id="nom"
                                    name="nom"
                                    value={formData.nom}
                                    onChange={handleChange}
                                    className="mt-2 block w-full p-4 border border-gray-300 rounded-md text-lg"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="prenom" className="block text-gray-700 font-medium text-xl">Prénom</label>
                                <input
                                    type="text"
                                    id="prenom"
                                    name="prenom"
                                    value={formData.prenom}
                                    onChange={handleChange}
                                    className="mt-2 block w-full p-4 border border-gray-300 rounded-md text-lg"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="tel" className="block text-gray-700 font-medium text-xl">Téléphone</label>
                                <input
                                    type="tel"
                                    id="tel"
                                    name="tel"
                                    value={formData.tel}
                                    onChange={handleChange}
                                    className="mt-2 block w-full p-4 border border-gray-300 rounded-md text-lg"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="sujet" className="block text-gray-700 font-medium text-xl">Sujet</label>
                                <input
                                    type="text"
                                    id="sujet"
                                    name="sujet"
                                    value={formData.sujet}
                                    onChange={handleChange}
                                    className="mt-2 block w-full p-4 border border-gray-300 rounded-md text-lg"
                                    required
                                />
                            </div>
                            <div className="relative">
                                <label htmlFor="message" className="block text-gray-700 font-medium text-xl">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows="6"
                                    className="mt-2 block w-full p-4 border border-gray-300 rounded-md text-lg pl-10"
                                    required
                                ></textarea>
                            </div>
                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    className="w-full py-5 bg-[#006462] text-[#ffdf76] font-semibold text-xl rounded-full"
                                >
                                    Envoyer
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="flex-shrink-0 bg-transparent">
                    <img src={image} alt="Image" className="lg:w-[40%] 2xl:w-[40%] xl:w-[40%] w-full md:w-[50%]  h-auto object-contain mx-auto" />
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default ContactForm;
