// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './components/Header'; 
// import Home from './Pages/Home'; 
// import Login from './Pages/InstallateurLogin';
// import Demande from './Pages/Demande';
// import Demande2 from './Pages/Demandeseconde.js';
// import Footer from './components/Footer'; 
// import Compte from './Pages/Compte.js';
// import Info from './Pages/Infos.js';
// import Archive from './Pages/archives.js';
// import Tarif from './Pages/Info&Tarifs.js';
// import Rgpt from './Pages/RGPD.js';
// import Contact from './Pages/Contact.js';


// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/installer-form-login"  element={<Login />} />
//         <Route path="/creation-demande" element={<Demande />} />
//         <Route path="/creation-demande2" element={<Demande2 />} />
//         <Route path="/mon-compte" element={<Compte/>} />
//         <Route path="/mes-informations" element={<Info/>} />
//         <Route path="/info&tarifs" element={<Tarif/>} />
//         <Route path="/RGPD" element={<Rgpt/>} />
//         <Route path="/contact" element={<Contact/>} />


//         <Route path="/archives" element={<Archive/>} />

//       </Routes>
//   </Router>
//   );
// }

// export default App;




import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './components/storeDemande.js'; // Ensure the correct path to your store
// import Header from './components/Header'; 
import Home from './Pages/Home'; 
import Login from './Pages/InstallateurLogin';
import Demande from './Pages/Demande';
import Demande2 from './Pages/Demandeseconde.js';
// import Footer from './components/Footer'; 
import Compte from './Pages/Compte.js';
import Info from './Pages/Infos.js';
import Archive from './Pages/archives.js';
import Tarif from './Pages/Info&Tarifs.js';
import Rgpt from './Pages/RGPD.js';
import Contact from './Pages/Contact.js';
import Cgv from './Pages/CGV.js';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/installer-form-login" element={<Login />} />
          <Route path="/creation-demande" element={<Demande />} />
          <Route path="/creation-demande2" element={<Demande2 />} />
          <Route path="/mon-compte" element={<Compte />} />
          <Route path="/mes-informations" element={<Info />} />
          <Route path="/info&tarifs" element={<Tarif />} />
          <Route path="/RGPD" element={<Rgpt />} />
          <Route path="/CGV-CGU" element={<Cgv />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/archives" element={<Archive />} />
        </Routes>
        {/* If you want the Header and Footer to be present on all pages, include them here */}
        {/* <Header />
        <Footer /> */}
      </Router>
    </Provider>
  );
}

export default App;
